import { rawRequest } from '../http_common'

class OnboardingDanamonService {
  onBoarding (id) {
    return rawRequest.post(`/danamon/createAccount/${id}`).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  inquiryStatusOnBoarding (id, body) {
    return rawRequest.post(`/danamon/inquiryOnBoardingStatus/${id}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e.getResponse)
      return Promise.reject(e)
    })
  }

  getStatus (id) {
    return rawRequest.get(`onboarding/getStatus/${id}`).then(res => {
      return res
    }).catch(e => {
      console.log(e.response)
      return Promise.reject(e)
    })
  }
}

export default new OnboardingDanamonService()
