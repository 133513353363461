import { authService } from '../services/auth.services'
// import router from '../../router/index'

const user = JSON.parse(localStorage.getItem('user'))
const isPresent = user !== null
const auth = {
  namespace: true,
  state: {
    user: isPresent ? user : null,
    register: {},
    forgotPass: {},
    resetPass: {},
    checkTokenReset: {},
    isLoading: false,
    suspendAcc: {},
    verify: {}
  },
  mutations: {
    registerSuccess (state, request) {
      state.register = request
    },
    clearRegister (state) {
      state.register = {}
    },
    changeLoading (state, payload) {
      state.isLoading = payload
    },
    loginSuccess (state, user) {
      state.user = user
    },
    clearLogin (state) {
      state.user = {}
    },
    forgotPassSuccess (state, payload) {
      state.forgotPass = payload
    },
    resetPassSuccess (state, payload) {
      state.resetPass = payload
    },
    clearForgotPass (state) {
      state.forgotPass = {}
    },
    clearResetPass (state) {
      state.resetPass = {}
    },
    deleteResetPass (state) {
      state.checkTokenReset = {}
    },
    checkTokenResetSuccess (state, payload) {
      state.checkTokenReset = payload
    },
    suspendAcc (state, payload) {
      state.suspendAcc = payload
    },
    clearTokenReset (state) {
      state.checkTokenReset = {}
    },
    verifyAccount (state, payload) {
      state.verify = payload
    }
  },
  actions: {
    register ({ commit }, { body }) {
      commit('changeLoading', true)
      authService.register(body)
        .then(
          res => {
            console.log(res.response)
            commit('registerSuccess', res)
            commit('changeLoading', false)
            commit('clearError')
          },
          error => {
            console.log(error.response)
            commit('clearRegister', error)
            commit('changeLoading', false)
            commit('onErrorRequest', error.response)
          }
        )
    },
    suspendAcc ({ commit }, { id, body }) {
      commit('changeLoading', true)
      authService.suspendAcc(id, body)
        .then(
          res => {
            commit('suspendAcc', res)
            commit('changeLoading', false)
            commit('setStatusError', false)
            commit('clearError')
          },
          error => {
            commit('changeLoading', false)
            commit('onErrorRequest', error.response)
          }
        )
    },
    clearLoading ({ commit }) {
      commit('changeLoading', false)
    },
    // login ({ commit }, { body }) {
    //   commit('changeLoading', true)
    //   authService.login(body)
    //     .then(
    //       res => {
    //         console.log(res)
    //         commit('loginSuccess', res)
    //         commit('changeLoading', false)
    //         commit('clearError')
    //         // router.push({ name: 'Home' })
    //         // router.go()
    //       },
    //       error => {
    //         console.log(error.response)
    //         commit('changeLoading', false)
    //         commit('clearLogin', error)
    //       }
    //     )
    // },
    logout ({ commit }) {
      authService.logout()
      commit('clearLogin')
    },
    forgotPass ({ commit }, { body }) {
      commit('changeLoading', true)
      authService.forgotPassword(body)
        .then(
          res => {
            console.log(res)
            commit('forgotPassSuccess', res)
            commit('clearError')
            commit('changeLoading', false)
          },
          error => {
            console.log(error)
            commit('clearForgotPass', error)
            commit('changeLoading', false)
            commit('onErrorRequest', error.response)
          }
        )
    },
    resetPassword ({ commit }, { body, params }) {
      commit('clearResetPass')
      commit('changeLoading', true)
      authService.resetPassword(body, params)
        .then(
          res => {
            console.log(res)
            commit('resetPassSuccess', res)
            commit('clearError')
            commit('changeLoading', false)
          },
          error => {
            console.log(error)
            commit('changeLoading', false)
            commit('onErrorRequest', error.response)
          }
        )
    },
    deletResetPass ({ commit }) {
      commit('deleteResetPass')
    },
    checkTokenReset ({ commit }, { token }) {
      commit('changeLoading', true)
      authService.checkResetToken(token)
        .then(
          res => {
            console.log(res)
            commit('checkTokenResetSuccess', res)
            commit('clearError')
            commit('changeLoading', false)
          },
          error => {
            console.log(error)
            commit('checkTokenResetSuccess', error.response.data)
            commit('changeLoading', false)
            commit('onErrorRequest', error.response)
          }
        )
    },
    verifyAccount ({ commit }, { params }) {
      commit('changeLoading')
      authService.verifyAcc(params)
        .then(
          res => {
            console.log(res)
            commit('verifyAccount', res)
            commit('clearError')
            commit('changeLoading', false)
          },
          error => {
            commit('changeLoading', false)
            commit('onErrorRequest', error.response)
          }
        )
    }
  }
}

export default auth
