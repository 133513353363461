<template>
  <div class="mt-5">
    <h4 class="top-subtitle2 mb-3 text-center">
      Business Accelerator untuk berbagai sektor
    </h4>
    <h2 class="title text-center">The <span class="tblue">solutions</span> we provide</h2>
    <!-- Venture Hub != venturehub -->
    <ul class="list-unstyled row">
      <li class="col-md-4 text-center">
        <img src="@/assets/img/vector-1.svg" class="img-fluid mb-3" />
        <h3 class="subtitle mb-3">Loan & Equity Funding</h3>
        <p class="text-gray mb-0 wh-p-narrow">
          Kami hubungkan Anda dengan investor, seperti supplier yang memberikan termin panjang ataupun penanaman modal dengan cara membeli saham baru.
        </p>
      </li>
      <li class="col-md-4 text-center">
        <img src="@/assets/img/vector-2.svg" class="img-fluid mb-3" />
        <h3 class="subtitle mb-3">Go-to Market</h3>
        <p class="text-gray mb-0 wh-p-narrow">
          Kami menghubungkan perusahaan Anda dengan customer baru baik B2B (eg. government deals) maupun B2C (eg. Tiktok), untuk meningkatkan revenue.
        </p>
      </li>
      <li class="col-md-4 text-center">
        <img
          src="@/assets/img/vector-3.svg"
          class="img-fluid mb-3"
        />
        <h3 class="subtitle mb-3">Restructuring, M&A</h3>
        <p class="text-gray mb-0 wh-p-narrow">
          Kami bantu perusahaan Anda untuk restrukturisasi hutang, modal, mencarikan mitra strategis untuk merger & akuisisi, dan bahkan akses ke pasar modal (IPO).
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, Divider, Row } from 'ant-design-vue'

Vue.use(Col)
Vue.use(Divider)
Vue.use(Row)

export default {
  name: 'Section2'
}
</script>

<style scoped lang="css">
.wh-p-narrow {
  width: 60%;
  margin: auto;
}
</style>
