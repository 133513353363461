<template>
  <div id="wrapper">
    <section>
      <div class="row kyc m-0">
        <div class="col-md-4 pt-5 pb-5">
          <router-link to="/" class="logo mb-4 d-block"
            ><img src="@/assets/img/logo.png"
          /></router-link>
          <h2 class="mb-3">
            Mulai Pendanaan. Danai, Bangun &amp; Kembangkan Bisnis Anda.
            <!-- Start Your Raise. Fund, Build &amp; Scale Your Startup. -->
          </h2>
          <p>
            Sekarang semua orang bisa memulai pendanaan. Sudah ada ribuan bisnis
            yang telah memakai jasa kami. Bergabung sekarang!
            <!-- Now anyone can fund your business. Thousands of startups have used
            our services to launch their fundraise. Join today! -->
          </p>
        </div>
        <router-view />
      </div>
    </section>
    <Footer1 />
  </div>
</template>
<script>
import Vue from 'vue'
import Footer1 from '../components/Layout/Footer1.vue'

Vue.use(Footer1)

export default {
  name: 'StartYourRaises',
  components: {
    Footer1
  },
  methods: {
    toHome () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
</style>
