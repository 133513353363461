<template>
  <!-- <div> -->
  <header
    :class="{ fixed: scrollPosition > 40, other: isOther}"
    :style="`${
      scrollPosition > 40
        ? 'background-color: white; border-bottom:1px solid rgb(224, 224, 224); '
        : ''
    }`"
  >
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/img/wh-black.svg"/>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
          </ul>
          <div class="form-inline my-2 my-lg-0">
            <button
              class="btn btn-outline-success my-2 my-sm-0 mr-2"
              @click="toLogin"
              type="button"
            >
              Masuk
            </button>
            <button
              class="btn btn-primary my-2 my-sm-0"
              type="button"
              @click="toRegister"
            >
              Daftar
            </button>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <!-- </div> -->
</template>

<script>
import Vue from 'vue'
import { Col, Row, Space } from 'ant-design-vue'

Vue.use(Col)
Vue.use(Row)
Vue.use(Space)

export default {
  name: 'Header',
  props: ['isHome', 'isDash', 'isOther'],
  data () {
    return {
      scrollPosition: null
    }
  },
  methods: {
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
    toLogin () {
      this.$router.push({ name: 'Login' })
    },
    toRegister () {
      this.$router.push({ name: 'Register' })
    },
    toListStartup () {
      this.$router.push({ name: 'ListStartup' })
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateScroll)
  }
}
</script>

<style scoped>
</style>
