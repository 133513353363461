import { rawRequest, multipartRequest } from '../http_common.js'

class TransactionService {
  getAll (id, params) {
    return rawRequest.get(`/transaction/${id}`, { params })
  }

  getById (id) {
    return rawRequest.get(`/transaction/detail/${id}`).then(res => {
      return res
    }).catch(e => {
      console.log(e.response)
      return Promise.reject(e)
    })
  }

  getTopup (id) {
    return rawRequest.get(`/transaction/detail/topup/${id}`).then(res => {
      return res
    }).catch(e => {
      console.log(e.response)
      return Promise.reject(e)
    })
  }

  sendBukti (id, body) {
    return multipartRequest.post(`/saldo/send-bukti/${id}`, body)
      .then(res => {
        return res
      }).catch(e => {
        console.log(e)
        return Promise.reject(e)
      })
  }

  getTransactionHistory (id, params) {
    return rawRequest.get(`/transaction/user/${id}`, { params })
  }

  cancelOrder (params) {
    return rawRequest.post(`/midtrans/cancel-transaction/${params}`)
  }
}

export default new TransactionService()
