const secondaryMarket = {
  state: {
    secondaryMarketId: '',
    secondaryMarketType: '',
    secondaryMarketTime1: '',
    secondaryMarketTime2: '',
    secondaryMarketFairPrice: '',
    hargaTertinggi: '',
    hargaTerendah: '',
    hargaPembuka: '',
    hargaPenutup: '',
    persenTertinggi: '',
    persenTerendah: ''
  },
  mutations: {
    changeId (state, payload) {
      state.secondaryMarketId = payload
    },
    changeType (state, payload) {
      state.secondaryMarketType = payload
    },
    changeTime1 (state, payload) {
      state.secondaryMarketTime1 = payload
    },
    changeTime2 (state, payload) {
      state.secondaryMarketTime2 = payload
    },
    changeFairPrice (state, payload) {
      state.secondaryMarketFairPrice = payload
    },
    changeTertinggi (state, payload) {
      state.hargaTertinggi = payload
    },
    changeTerendah (state, payload) {
      state.hargaTerendah = payload
    },
    changePembuka (state, payload) {
      state.hargaPembuka = payload
    },
    changePenutup (state, payload) {
      state.hargaPenutup = payload
    },
    changePersenTinggi (state, payload) {
      state.persenTertinggi = payload
    },
    changePersenRendah (state, payload) {
      state.persenTerendah = payload
    }

  },
  actions: {
    changeIdSecondaryMarket ({ commit }, { val }) {
      commit('changeId', val)
    },
    changeTypeSecondaryMarket ({ commit }, { val }) {
      commit('changeType', val)
    },
    changeTimeSecondaryMarket1 ({ commit }, { val }) {
      commit('changeTime1', val)
    },
    changeTimeSecondaryMarket2 ({ commit }, { val }) {
      commit('changeTime2', val)
    },
    changeFairPriceSecondaryMarket ({ commit }, { val }) {
      commit('changeFairPrice', val)
    },
    secondaryMarketTertinggi ({ commit }, { val }) {
      commit('changeTertinggi', val)
    },
    secondaryMarketTerendah ({ commit }, { val }) {
      commit('changeTerendah', val)
    },
    secondaryMarketPembuka ({ commit }, { val }) {
      commit('changePembuka', val)
    },
    secondaryMarketPenutup ({ commit }, { val }) {
      commit('changePenutup', val)
    },
    secondaryMarketPersenTinggi ({ commit }, { val }) {
      commit('changePersenTinggi', val)
    },
    secondaryMarketPersenRendah ({ commit }, { val }) {
      commit('changePersenRendah', val)
    }
  }
}

export default secondaryMarket
