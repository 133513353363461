<template>
  <div class="col-md-5">
    <a-spin :spinning="isLoading">
      <form class="form-sign">
        <h3>Daftar Akun</h3>
        <p class="mb-4">
          Daftarkan bisnis Anda sekarang.
          <!-- Start building your diversified, vetted startup portfolio today. -->
        </p>
        <div class="row" v-show="isFromSetting">
          <div class="col-md-12">
            <div class="form-group">
              <label for="noKtp">Nomor KTP</label>
              <input
                type="text"
                disabled
                v-model="noKtp"
                class="form-control"
                placeholder="Nomor KTP" />
            </div>
          </div>
        </div>
        <div class="row" v-show="isFromSetting">
          <div class="col-md-6">
            <div class="form-group">
              <label for="noKtp">Tempat Lahir</label>
              <input
                type="text"
                disabled
                v-model="tempatLahir"
                class="form-control"
                placeholder="Tempat Lahir" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Tanggal Lahir<span class="text-red">*</span></label>
              <div class="input-group">
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <img src="@/assets/img/CalendarOutline.png" />
                  </div>
                </div>
                <date-picker ref="datepickerTanggalLahir"
                  type="text" class="form-control" id="inputDate" v-model="tanggalLahir"
                  disabled format="DD-MM-YYYY" value-type="DD-MM-YYYY" placeholder="DD-MM-YYYY" />
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Nama Depan Sesuai KTP</label>
              <input
                v-model="$v.firstName.$model"
                :class="{ 'invalid is-invalid': $v.firstName.$error }"
                type="text"
                :disabled="isFromSetting"
                class="form-control"
                placeholder="Nama Depan Anda"
              />
              <div v-show="$v.firstName.$error">
                <small v-show="!$v.firstName.alpha" id="passwordHelp" class="text-danger">
                  Hanya dapat diinputkan oleh huruf.
                </small>
                <small v-show="!$v.firstName.required" id="passwordHelp" class="text-danger">
                  Harus diisi.
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Nama Belakang Sesuai KTP</label>
              <input
                v-model="$v.lastName.$model"
                :class="{ 'invalid is-invalid': $v.lastName.$error }"
                type="text"
                :disabled="isFromSetting"
                class="form-control"
                placeholder="Nama Belakang Anda"
              />
              <div v-show="$v.lastName.$error">
                <small v-show="!$v.lastName.alpha" id="passwordHelp" class="text-danger">
                  Hanya dapat diinputkan oleh huruf.
                </small>
                <small v-show="!$v.lastName.required" id="passwordHelp" class="text-danger">
                  Harus diisi.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="">Email</label>
          <input
            v-model.trim="$v.emailAddress.$model"
            type="text"
            :class="{ 'invalid is-invalid': $v.emailAddress.$error }"
            class="form-control"
            placeholder="Alamat Email Anda"
          />
          <div v-if="$v.emailAddress.$error">
            <div v-show="!$v.emailAddress.email">
              <small id="passwordHelp" class="text-danger">
                Tolong gunakan format email yang benar
              </small>
            </div>
            <div v-show="!$v.emailAddress.required" >
              <small id="passwordHelp" class="text-danger">
                Harus diisi.
              </small>
            </div>
            <div v-show="!$v.emailAddress.emailBlacklist">
              <small id="passwordHelp" class="text-danger">
                Email yang Anda masukkan telah diblokir oleh sistem kami.
              </small>
            </div>
          </div>
          <div v-if="!emailAvailableMessage">
            <small class="text-red">Email sudah terdaftar, silahkan gunakan email lain</small>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Kata Sandi</label>
              <div class="position-relative">
                <input
                  v-model.trim="$v.password.$model"
                  @keyup="checkPassword"
                  :class="{ 'invalid is-invalid': $v.password.$error }"
                  :type="isVisible ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Kata Sandi Anda"
                />
                <button
                  v-if="!isVisible"
                  @click="isVisible = true"
                  class="btn-eye"
                  type="button"
                >
                  <img src="@/assets/img/eye.png" />
                </button>
                <button
                  v-else
                  @click="isVisible = false"
                  class="btn-eye"
                  type="button"
                >
                  <img src="@/assets/img/invisible.png" />
                </button>
              </div>
              <div v-show="$v.password.$error">
                <small id="passwordHelp" class="text-danger">
                  Harus diisi.
                </small>
              </div>
              <div v-show="passwordValidate">
                <small id="passwordHelp" class="text-danger">
                  Kata sandi minimal 8 huruf dan harus mencakup huruf kapital,
                  huruf kecil, nomor dan simbol.
                  <!-- your password must be beetween 8 - 15 characters and must be
                  contain at least three of the following: upper case letter,
                  lower case letter, number, symbol -->
                </small>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group">
              <label for="">Ulangi Kata Sandi</label>
              <div class="position-relative">
                <input
                  v-model.trim="$v.passwordRepeat.$model"
                  :class="{ 'invalid is-invalid': $v.passwordRepeat.$error }"
                  :type="isVisible2 ? 'text' : 'password'"
                  class="form-control"
                  placeholder="Ulangi Kata Sandi"
                />
                <button
                  v-if="!isVisible2"
                  @click="isVisible2 = true"
                  class="btn-eye"
                  type="button"
                >
                  <img src="@/assets/img/eye.png" />
                </button>
                <button
                  v-else
                  @click="isVisible2 = false"
                  class="btn-eye"
                  type="button"
                >
                  <img src="@/assets/img/invisible.png" />
                </button>
              </div>
              <div v-show="!isSame">
                <small id="passwordHelp" class="text-danger">
                  Kata sandi tidak sesuai.
                </small>
              </div>
              <div v-show="$v.passwordRepeat.$error">
                <small id="passwordHelp" class="text-danger">
                  Harus diisi.
                </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="">Tipe Akun</label>
              <div class="styled-select text-black arrow-black">
                <select :disabled="isFromSetting" v-model="typeAccount">
                  <option value="" selected hidden>Pilih Tipe Akun</option>
                  <!--<option value="PEMODAL">Pemodal</option>-->
                  <option value="PENERBIT">Perusahaan</option>
                </select>
              </div>
              <div v-show="$v.typeUser.$error">
                <small id="passwordHelp" class="text-danger">
                  Harus diisi.
                </small>
              </div>
            </div>
          </div>
          <div v-if="isPemodal" class="col">
            <div class="form-group">
              <label for="">Tipe User</label>
              <div class="styled-select text-black arrow-black">
                <select :disabled="isFromSetting" v-model="typeUser">
                  <option value="" selected hidden>Pilih Tipe User</option>
                  <option value="INDIVIDU">Individu</option>
                  <option value="INSTITUSI">Institusi</option>
                </select>
              </div>
              <div v-show="$v.typeUser.$error">
                <small id="passwordHelp" class="text-danger">
                  Harus diisi.
                </small>
              </div>
            </div>
          </div>
          <div v-if="isInstitusi" class="col">
            <div class="form-group">
              <label for="">Posisi</label>
              <div class="styled-select text-black arrow-black">
                <select :disabled="isFromSetting" v-model="position">
                  <option value="" selected hidden>Posisi anda di perusahaan</option>
                  <option value="DIREKTUR">Direktur</option>
                  <option value="NON_DIREKTUR">Non Direktur</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="isNotDirektur" class="col-12">
            <div class="form-group">
              <label for="">Surat Kuasa</label>
              <div class="row">
                <div class="form-group d-flex">
                  <input :title="suratKuasa.name" v-model="suratKuasa.name" class="form-control mr-1 col-md-8" type="text" name="" id="" disabled>
                  <button type="button" @click="browseSuratKuasa" class="btn btn-primary col-md-4">Pilih</button>
                </div>
                <input @change="selectSuratKuasa" accept=".pdf" style="display: none;" type="file" ref="inp_suratKuasa" name="" id="">
              </div>
            </div>
          </div>
      </div>
        <!-- <div class="form-group mt-4">
          <p>
            Dengan menekan tombol 'Daftar', berarti Anda sudah menyetujui
            <a href="/terms-condition" class="text-blue"
              >Syarat dan Ketentuan</a
            >
            serta
            <a href="/privacy-policy" class="text-blue">Kebijakan Privasi</a>.
          </p>
        </div> -->
        <div class="form-group">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            sitekey="6LeDZBwbAAAAAL7gqM2Ct0pCK1KLo0L4hp26kux7"
            :loadRecaptchaScript="true"
          ></vue-recaptcha>
        </div>
        <div class="form-group">
          <button
            v-on:click="openModalTermCondition"
            class="btn btn-primary my-2 w-100 mt-4"
            type="button"
            data-toggle="modal" data-target="#modalTermsCondition"
            :disabled="isDisable"
          >
            Daftar
          </button>
          <button
            v-show="!isFromSetting"
            v-on:click="toLogin()"
            class="btn btn-outline-success my-2 w-100 mt-2 no-border"
          >
            Anda sudah mempunyai akun? Masuk
          </button>
        </div>
      </form>
    </a-spin>
    <modal-success
      v-if="isSuccess"
      @close="toLogin"
      message="Tolong cek email Anda"
    />
    <modal-error
      v-if="isError"
      @close="isError = false"
      :message="errorMessage"
    />
    <terms-condition-modal @submit="register" @close="closeModalTermCondition" v-if="isTermCondition"></terms-condition-modal>
  </div>
</template>

<script>
import { Spin } from 'ant-design-vue'
import Vue from 'vue'
import ModalSuccess from '../alert/ModalSuccess.vue'
import VueRecaptcha from 'vue-recaptcha'
import { required, email, helpers, requiredIf } from 'vuelidate/lib/validators'
import ModalError from '../alert/ModalError.vue'
import { authService } from '../../store/services/auth.services'
import TermsConditionModal from '../alert/TermsConditionModal.vue'
import CopyDataService from '../../store/services/CopyDataService'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import { debounce } from '../custom-js/Utils'
import { kycService } from '../../store/services/kyc.services'

Vue.use(Spin)

const emailBlacklist = (value) => {
  const email = value.split('@')[1].toLowerCase()
  const blacklisted = ['mailinator.com', 'yopmail.com', 'guerrillamail.com', 'maildrop.cc', 'getnada.com', 'airmail.cc', 'temp-mail.org']
  return !blacklisted.includes(email)
}
export default {
  name: 'Register',
  components: {
    VueRecaptcha,
    ModalSuccess,
    ModalError,
    DatePicker,
    TermsConditionModal
  },
  data () {
    return {
      isError: false,
      isSuccess: false,
      isTermCondition: false,
      firstName: '',
      lastName: '',
      emailAddress: '',
      noKtp: '',
      password: '',
      passwordRepeat: '',
      isLoading: false,
      isVisible: false,
      isVisible2: false,
      errorMessage: 'Error',
      isVerify: false,
      passwordValidate: false,
      typeUser: '',
      typeAccount: '',
      position: '',
      maxFileSize: 12000000,
      suratKuasa: '',
      isFromSetting: false,
      tanggalLahir: '',
      tempatLahir: '',
      emailAvailable: true,
      emailBlackListed: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      if (from.name === 'Settings') {
        vm.isFromSetting = true
        const user = vm.$store.state.kyc.detailUser
        const namaDepan = user.namaDepan
        const isNameStarWithSpace = await namaDepan.startsWith(' ')
        const namaDepanEdit = await isNameStarWithSpace ? namaDepan.substring(1) : namaDepan
        vm.firstName = namaDepanEdit
        vm.lastName = user.namaBelakang
        vm.typeAccount = user.tipeAkun === 'PEMODAL' ? 'PEMODAL' : 'PENERBIT'
        vm.noKtp = user.noKtp
        vm.typeUser = user.tipeInvestor
        vm.tanggalLahir = user.dateOfBirth
        vm.tempatLahir = user.tempatLahir
        if (vm.typeUser !== 'INDIVIDU') {
          vm.position = user.company.suratKuasa ? 'NON_DIREKTUR' : 'DIREKTUR'
        }
      } else {
        vm.isFromSetting = false
      }
    })
  },
  validations: {
    emailAddress: {
      required,
      email,
      emailBlacklist
    },
    lastName: {
      required,
      isNameValid: helpers.regex('isNameValid', /^[a-z ]*$/i)
    },
    firstName: {
      required,
      isNameValid: helpers.regex('isNameValid', /^[a-z ]*$/i)
    },
    password: {
      required
    },
    passwordRepeat: {
      required
    },
    typeAccount: {
      required
    },
    typeUser: {
      required: requiredIf(function () {
        return this.isPemodal
      })
    },
    position: {
      required: requiredIf(function () {
        return this.isInstitusi
      })
    },
    suratKuasa: {
      required: requiredIf(function () {
        return this.isNotDirektur
      })
    }
  },
  computed: {
    isPemodal () {
      if (this.typeAccount === 'PEMODAL') {
        return true
      }
      return false
    },
    isInstitusi () {
      if (this.typeUser === 'INSTITUSI') {
        return true
      } return false
    },
    isSame () {
      if (this.password === this.passwordRepeat) {
        return true
      } else {
        return false
      }
    },
    isDisable () {
      if (!this.passwordValidate && !this.$v.$invalid &&
      this.isVerify && this.isSame && this.emailAvailable) {
        return false
      } else {
        return true
      }
    },
    isNotDirektur () {
      if (this.position === 'NON_DIREKTUR') {
        return true
      } return false
    },
    emailAvailableMessage () {
      return this.emailAvailable
    }
  },
  watch: {
    typeAccount () {
      if (this.typeAccount === 'PENERBIT') {
        this.typeUser = 'INSTITUSI'
      }
    },
    emailAddress (...args) {
      this.debouncedFetch(...args)
    }
  },
  created () {
    this.isError = false
    this.isSuccess = false
    this.debouncedFetch = debounce((newValue, oldValue) => {
      this.checkAvailableEmail()
    }, 1000)
  },
  methods: {
    checkPassword () {
      const checkPassword = /^(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?])(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/
      // const checkPassword = /^(?=.*[0-9])(?=.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~])(?=.*[A-Z])(?=.*\d)[a-zA-Z0-9`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]{6,16}$/
      this.passwordValidate = !checkPassword.test(this.password)
    },
    onVerify: function () {
      this.isVerify = true
    },
    onExpired: function () {
      this.isVerify = false
      this.$refs.recaptcha.reset()
    },
    async register () {
      if (!this.isDisable) {
        this.isLoading = true
        const firsName = this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1)
        const lastName = this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1)
        const body = {
          namaDepan: firsName,
          namaBelakang: lastName,
          email: this.emailAddress.toLowerCase(),
          password: this.password,
          tipeAkun: this.typeAccount,
          typeUser: this.typeUser
        }
        if (this.isFromSetting) {
          body.tanggalLahir = this.tanggalLahir
          body.tempatLahir = this.tempatLahir
          body.noKtp = this.noKtp
          const isPassedDataCheck = await this.checkData(body)
          if (!isPassedDataCheck) {
            this.openErrorModal()
            this.isLoading = false
            this.errorMessage = 'Anda telah memiliki akun Penerbit & Pemodal'
            return
          }
        }
        const form = new FormData()
        form.append('req', JSON.stringify(body))
        form.append('suratKuasa', this.suratKuasa)
        authService.register(form).then(() => {
          this.isLoading = false
          if (this.isFromSetting) {
            CopyDataService.pickCopyData(body).then(_ => {
              this.openSuccessModal()
              this.isLoading = false
            }).catch(err => {
              console.log(err)
              this.errorMessage = err.response.data.errorMessages[0].message
              this.openErrorModal()
              this.isLoading = false
            })
          } else {
            this.openSuccessModal()
            this.isLoading = false
          }
        }).catch(err => {
          this.isLoading = false
          this.errorMessage = err.response.data.errorMessages[0].message
          this.openErrorModal()
        })
      } else {
        this.openErrorModal()
        this.errorMessage = 'Tolong isi form dan captcha'
      }
    },
    toLogin () {
      this.isSuccess = false
      if (this.isFromSetting) {
        this.successModalClickEvent()
      } else {
        this.$router.push({ name: 'Login' })
      }
    },
    openModalTermCondition () {
      this.isTermCondition = true
      window.$('#modalTermsCondition').modal('show')
    },
    closeModalTermCondition () {
      this.isTermCondition = true
      window.$('#modalTermsCondition').modal('hide')
    },
    openSuccessModal () {
      this.isSuccess = true
    },
    successModalClickEvent () {
      this.$router.push({ name: 'Login' }).then(() => {
        this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
        this.$store.dispatch('logout')
      })
    },
    openErrorModal () {
      this.isError = true
    },
    browseSuratKuasa () {
      this.$refs.inp_suratKuasa.click()
    },
    selectSuratKuasa () {
      const a = this.$refs.inp_suratKuasa.files[0]
      console.log('selected files', a)
      if (this.checkFile(a)) {
        this.suratKuasa = a
      }
    },
    checkFile (item) {
      const size = item.size
      if (size < this.maxFileSize) {
        return true
      } else {
        this.openErrorModal()
        this.errorMessage = 'File Melebihi Kapasitas Maksimum (10MB)'
      }
    },
    async checkData (body) {
      return CopyDataService.checkData(body).then(res => {
        return !res.data.data.isPemodalAndPenerbit && res.data.data.existing
      }).catch(e => {
        this.errorMessage = 'Internal Server Error'
        return false
      })
    },
    checkAvailableEmail () {
      kycService.checkAvailableEmail(this.emailAddress).then(res => {
        console.log('check available email', res)
        this.emailAvailable = res.data.data.available
      }).catch(e => {
        console.log('error', e.response)
      })
    }
  }
}
</script>

<style scoped>
.invalid {
  border-color: #dc3545;
  padding-right: 0.375rem 0.75rem;
  /* background-image: url(data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e); */
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
</style>
