import WithdrawService from '../services/WithdrawServices'

const withdraw = {
  state: {
    isLoading: false,
    withdraw: {}
  },
  mutations: {
    loading (state, status) {
      state.isLoading = status
    },
    withdrawWallet (state, payload) {
      state.withdraw = payload
    },
    clearWithdraw (state) {
      state.withdraw = {}
    }
  },
  actions: {
    withdrawWallet ({ commit }, { body }) {
      commit('loading', true)
      WithdrawService.withdraw(body).then(
        res => {
          console.log(res)
          commit('withdrawWallet', res)
          commit('loading', false)
        },
        e => {
          console.log(e)
          commit('loading', false)
        }
      )
    }
  }
}

export default withdraw
