import { rawRequest } from '../http_common'

class UserDebit {
  getDebit (email) {
    return rawRequest.get(`/userDebit/getUserDebit/${email}`).then(res => {
      console.log(res)
      return res
    }).catch(e => {
      console.error(e)
      return Promise.reject(e)
    })
  }
}

export default new UserDebit()
