import DanamonService from '../services/DanamonService'

const danamon = {
  state: {
    isLoading: false,
    resDebitRegistration: {},
    resVerifyDebit: {},
    resHoldBalanceOtp: {},
    resVerifyHoldBalance: {},
    resRetryOtp: {},
    resInquiryVid: {},
    resVerifyBalanceInquiry: {},
    resFundDisbursement: {},
    resCheckInquiryBalance: {},
    resHoldBalance: {},
    resUnregisterDebit: {},
    resUnholdBalance: {}
  },
  mutations: {
    setLoading (state, payload) {
      state.isLoading = payload
    },
    setDebitRegistration (state, payload) {
      state.resDebitRegistration = payload
    },
    setVerifyDebit (state, payload) {
      state.resVerifyDebit = payload
    },
    setHoldBalanceOtp (state, payload) {
      state.resHoldBalanceOtp = payload
    },
    setVerifyHoldBalance (state, payload) {
      state.resVerifyHoldBalance = payload
    },
    setRetryOtp (state, payload) {
      state.resRetryOtp = payload
    },
    setInquiryVid (state, payload) {
      state.resInquiryVid = payload
    },
    setVerifyBalanceInquiry (state, payload) {
      state.resVerifyBalanceInquiry = payload
    },
    setFundDisbursement (state, payload) {
      state.resFundDisbursement = payload
    },
    setCheckInquiryBalance (state, payload) {
      state.resCheckInquiryBalance = payload
    },
    setHoldBalance (state, payload) {
      state.resHoldBalance = payload
    },
    setUnregisterDebit (state, payload) {
      state.resUnregisterDebit = payload
    },
    setUnholdBalance (state, payload) {
      state.resUnholdBalance = payload
    },
    clearRegisterDebit (state) {
      state.resDebitRegistration = null
    },
    clearVerifyDebit (state) {
      state.resVerifyDebit = null
    },
    clearHoldBalanceOtp (state) {
      state.resHoldBalanceOtp = {}
    },
    clearVerifyHoldBalance (state) {
      state.resVerifyHoldBalance = {}
    },
    clearRetryOtp (state) {
      state.resRetryOtp = {}
    },
    clearInquiryVid (state) {
      state.resInquiryVid = {}
    },
    clearFundDisbursement (state) {
      state.resFundDisbursement = {}
    },
    clearCheckInquiryBalance (state) {
      state.resCheckInquiryBalance = {}
    },
    clearHoldBalance (state) {
      state.resHoldBalance = {}
    },
    clearUnregisterDebit (state) {
      state.resUnregisterDebit = {}
    },
    clearUnholdBalance (state) {
      state.resUnholdBalance = {}
    },
    clearVerifyBalanceInquiry (state) {
      state.resVerifyBalanceInquiry = {}
    }
  },
  actions: {
    debitRegistration ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.debitRegistration(body, email)
        .then(
          res => {
            commit('setDebitRegistration', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('setDebitRegistration', error.response)
            // commit('clearRegisterDebit')
          }
        ).finally(() => commit('setLoading', false))
    },

    verifyDebit ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.verifyDebit(body, email)
        .then(
          res => {
            commit('setVerifyDebit', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('setVerifyDebit', error.response)
            // commit('clearVerifyDebit')
          }
        ).finally(() => commit('setLoading', false))
    },

    holdBalanceOtp ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.holdBalanceOtp(body, email)
        .then(
          res => {
            commit('setHoldBalanceOtp', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearHoldBalanceOtp')
          }
        ).finally(() => commit('setLoading', false))
    },

    verifyHoldBalance ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.verifyHoldBalance(body, email)
        .then(
          res => {
            console.log(res)
            commit('setVerifyHoldBalance', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearVerifyHoldBalance')
          }
        ).finally(() => commit('setLoading', false))
    },

    retryOtp ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.retryOtp(body, email)
        .then(
          res => {
            commit('', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearRetryOtp')
          }
        ).finally(() => commit('setLoading', false))
    },

    inquiryVid ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.inquiryVid(body, email)
        .then(
          res => {
            commit('setInquiryVid', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('setInquiryVid', error.response)
            commit('clearInquiryVid')
          }
        ).finally(() => commit('setLoading', false))
    },

    fundDisbursement ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.fundDisbursement(body, email)
        .then(
          res => {
            commit('setFundDisbursement', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearFundDisbursement')
          }
        ).finally(() => commit('setLoading', false))
    },

    checkInquiryBalance ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.checkInquiryBalance(body, email)
        .then(
          res => {
            commit('setCheckInquiryBalance', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearCheckInquiryBalance')
          }
        )
    },

    holdBalance ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.holdbalance(body, email)
        .then(
          res => {
            commit('setHoldBalance', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearHoldBalance')
          }
        ).finally(() => commit('setLoading', false))
    },

    unregisterDebit ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.unregisterDebit(body, email)
        .then(
          res => {
            commit('setUnregisterDebit', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearUnregisterDebit')
          }
        ).finally(() => commit('setLoading', false))
    },

    unholdBalance ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.unholdBalance(body, email)
        .then(
          res => {
            commit('setUnholdBalance', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearUnholdBalance')
          }
        ).finally(() => commit('setLoading', false))
    },

    verifyBalanceInquiry ({ commit }, { body, email }) {
      commit('setLoading', true)
      DanamonService.verifyBalanceInquiry(body, email)
        .then(
          res => {
            commit('setVerifyBalanceInquiry', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearVerifyBalanceInquiry')
          }
        ).finally(() => commit('setLoading', false))
    }
  }
}

export default danamon
