import UserDebit from '../services/UserDebit'

const userDebit = {
  state: {
    resUserDebit: {},
    isUserDebit: false
  },
  mutations: {
    setUserDebit (state, payload) {
      state.resUserDebit = payload
    },
    clearUserDebit (state) {
      state.resUserDebit = {}
    },
    setIsUserDebit (state, payload) {
      console.log(payload)
      state.isUserDebit = payload
    }
  },
  actions: {
    getUserDebit ({ commit }, { email }) {
      commit('loading', true)
      UserDebit.getDebit(email)
        .then(
          res => {
            commit('setUserDebit', res.data.data)
            commit('setIsUserDebit', true)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearUserDebit')
          }
        ).finally(() => commit('loading', false))
    }
  }
}

export default userDebit
