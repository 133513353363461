<template>
  <input type="text" ref="inputRef">
</template>

<script>
import { useCurrencyInput } from 'vue-currency-input'
import { computed, watch } from '@vue/composition-api'
export default {
  name: 'CurrencyInput',
  props: {
    value: {
      type: Number,
      default: null
    },
    currency: {
      type: String,
      required: true
    },
    hideGroupingSeparatorOnFocus: {
      type: Boolean,
      required: true
    }
  },
  setup (props) {
    const options = computed(() => ({ currency: props.currency, hideGroupingSeparatorOnFocus: props.hideGroupingSeparatorOnFocus }))
    const { inputRef, setValue } = useCurrencyInput(options.value)

    watch(
      () => props.value,
      (value) => {
        setValue(value)
      }
    )

    return { inputRef }
  }

}
</script>

<style>

</style>
