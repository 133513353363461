<template>
  <div class="mt-5 start-register">
    <div class="wh-blackbox">
      <div class="row">
        <div class="col-md-9">
          <h4 class="mb-2 text-left">Pendaftaran Usaha</h4>
          <span style="font-size: 24px;">Program bisnis akselerator kami disesuaikan dengan kebutuhan unik perusahaan Anda</span>
        </div>
        <div class="col-md-3 text-right">
          <a v-if="!isloggedUser" @click="toRegister" class="btn btn-primary mt-3 d-inline-block" type="button">Daftar Sekarang</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, Divider, Row } from 'ant-design-vue'

Vue.use(Col)
Vue.use(Divider)
Vue.use(Row)

export default {
  name: 'Section6',
  computed: {
    isloggedUser () {
      const user = localStorage.getItem('user')
      return (user != null)
    }
  },
  methods: {
    toRegister () {
      this.$router.push({ name: 'Register' })
    },
    toStartRaises () {
      const loggedIn = localStorage.getItem('user')
      if (!loggedIn) {
        this.$router.push({ name: 'Login' })
      } else {
        this.$router.push({ name: 'StartYourRaises' })
      }
    }
  }
}
</script>

<style scoped>
.start-register .btn {
  background: #1FBEE8;
  padding: 12px 30px;
  border: 2px solid #1FBEE8;
}
.btn-primary:hover {
  background: transparent;
}
.wh-blackbox {
  background-color: #222531;
  border-radius: 10px;
  padding: 40px;
  color: #FFFFFF;
  margin: 50px;
}
</style>
