import { rawRequest } from '../http_common.js'

class WithdrawService {
  withdraw (body) {
    return rawRequest.post('/withdraw/request', body)
      .then(res => {
        return res
      }).catch(e => {
        return Promise.reject(e)
      })
  }

  history (id, params) {
    return rawRequest.get(`/withdraw/${id}`, { params })
      .then(res => {
        const data = res
        return data
      }).catch(e => {
        return Promise.reject(e)
      })
  }
}

export default new WithdrawService()
