import { rawRequest } from '../http_common.js'

class SettingService {
  getSetting () {
    return rawRequest.get('/setting').then(res => {
      return res.data.data
    }).catch(e => {
      console.error(e.response)
      return Promise.reject(e)
    })
  }
}

export default new SettingService()
