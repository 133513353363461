import { multipartRequest, rawRequest } from '../http_common.js'

class TopupService {
  topup (data, id) {
    return multipartRequest.post(`/saldo/send-bukti/${id}`, data)
      .then(res => {
        return res
      }).catch(e => {
        console.log(e)
        return Promise.reject(e)
      })
  }

  requestTopup (body) {
    return rawRequest.post('/saldo/topup', body)
      .then(res => {
        return res.data.data
      }).catch(e => {
        console.log(e.response)
        return Promise.reject(e)
      })
  }

  requestTopupMidtrans (body) {
    return rawRequest.post('/saldo/topup/midtrans', body)
      .then(res => {
        return res.data.data
      }).catch(e => {
        console.log(e.response)
        return Promise.reject(e)
      })
  }
}

export default new TopupService()
