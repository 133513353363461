import Vue from 'vue'
import App from './App.vue'
import 'ant-design-vue/dist/antd.css'
import $ from 'jquery'
import VueCookies from 'vue-cookies'
import './registerServiceWorker'
import router from './router'
import store from './store/store.js'
import VueCurrencyFilter from 'vue-currency-filter'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import 'leaflet/dist/leaflet.css'
import numeral from 'numeral'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import CurrencyInput from './components/custom-component/CurrencyInput.vue'
import 'cropperjs/dist/cropper.css'

import ModalError from './components/alert/ModalError'

// chart
import HighchartsVue from 'highcharts-vue'
Vue.use(VueCookies)
Vue.use(HighchartsVue)

// import css
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(VueToast)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
// Vue.use(VueReCaptcha, { siteKey: '226946630323-g0k7c6vjggsdqvv8rvbg5gfetfmsk4gd.apps.googleusercontent.com' })
Vue.use(VueCurrencyFilter,
  {
    symbol: 'Rp.',
    thousandsSeparator: '.',
    fractionCount: 0,
    fractionSeparator: ',',
    symbolPosition: 'front',
    symbolSpacing: true
  }
)
Vue.filter('percentage', function (value) {
  return numeral(value).format('0,0')
})

Vue.component('modal-error', ModalError)
Vue.component('currency-input', CurrencyInput)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  $,
  validations: {},
  render: h => h(App)
}).$mount('#app')
