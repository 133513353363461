import StartFundService from '../services/StartFundService'

const startFund = {
  namespace: true,
  state: {
    createFund: {},
    doc: {},
    isLoading: false,
    typeEffect: '',
    haveDraft: false,
    dataPerusahaan: {},
    dataFinance: {},
    dataEfek: {},
    dataVenturehub: {},
    steps: {},
    draftId: 0
  },
  mutations: {
    changeLoading (state, payload) {
      state.isLoading = payload
    },
    createStartFund (state, payload) {
      state.createFund = payload
    },
    clearStartFund (state) {
      state.createFund = {}
    },
    uploadDoc (state, payload) {
      state.doc = payload
    },
    clearDoc (state) {
      state.doc = {}
    },
    changeTypeEffect (state, payload) {
      state.typeEffect = payload
    },
    setDataPerusahaan (state, payload) {
      state.dataPerusahaan = payload
    },
    clearDataPerusahaan (state) {
      state.dataPerusahaan = {}
    },
    setDataFinance (state, payload) {
      state.dataFinance = payload
    },
    clearDataFinance (state) {
      state.dataFinance = {}
    },
    setDataEfek (state, payload) {
      state.dataEfek = payload
    },
    clearDataEfek (state) {
      state.dataEfek = {}
    },
    setDataVenturehub (state, payload) {
      state.dataVenturehub = payload
    },
    clearDataVenturehub (state) {
      state.dataVenturehub = {}
    },
    setHaveDraft (state, payload) {
      state.haveDraft = payload
    },
    clearDraft (state) {
      state.haveDraft = false
    },
    setSteps (state, payload) {
      state.steps = payload
    },
    clearSteps (state) {
      state.steps = {}
    },
    setDraftId (state, payload) {
      state.draftId = payload
    },
    clearDraftId (state) {
      state.draftId = 0
    }
  },
  actions: {
    createFund ({ commit }, { body, id }) {
      commit('changeLoading', true)
      StartFundService.addFund(id, body)
        .then(
          res => {
            // console.log(res)
            commit('changeLoading', false)
            commit('createStartFund', res)
            commit('clearError')
          },
          error => {
            // console.log(error.response)
            commit('changeLoading', false)
            commit('onErrorRequest', error.response)
            commit('clearStartFund', error)
          }
        )
    },
    uploadDoc ({ commit }, { data }) {
      commit('changeLoading', true)
      StartFundService.uploadDocs(data)
        .then(
          res => {
            // console.log(res)
            commit('changeLoading', false)
            commit('uploadDoc', res)
            commit('clearError')
          },
          error => {
            // console.log(error)
            commit('clearDoc', error)
            commit('onErrorRequest', error.response)
            commit('changeLoading', false)
          }
        )
    },
    clearDraftState ({ commit }) {
      commit('clearDataPerusahaan')
      commit('clearDataFinance')
      commit('clearDataEfek')
      commit('clearDataVenturehub')
      commit('clearSteps')
      commit('clearDraftId')
    },
    checkDraft ({ dispatch, commit, getters }) {
      StartFundService.getDraftCount().then(res => {
        commit('setHaveDraft', res.data.data.draft)
        commit('setSteps', res.data.data.steps)
        commit('setDraftId', res.data.data.projectId)
        if (!res.data.data.draft) {
          dispatch('clearDraftState')
        }
        const steps = res.data.data.steps
        // console.log('check', steps.dataVenturehub || (steps.dataEfek && getters.getHaveDraft))
        if (steps.dataPerusahaan) {
          dispatch('getDataPerusahaan', { projectId: getters.getDraftId })
        }
        if (steps.dataFinance) {
          dispatch('getDataFinance', { projectId: getters.getDraftId })
        }
        if (steps.dataEfek) {
          dispatch('getDataEfek', { projectId: getters.getDraftId })
        }
        if (steps.dataVenturehub || (steps.dataEfek && getters.getHaveDraft)) {
          dispatch('getDataVenturehub', { projectId: getters.getDraftId })
        }
      })
    },
    getDataPerusahaan ({ commit }, { projectId }) {
      StartFundService.getDataPerusahaan(projectId).then(res => {
        commit('setDataPerusahaan', res.data.data)
      }).catch(e => {
        console.error(e)
        // commit('clearDataPerusahaan')
      })
    },
    getDataFinance ({ commit }, { projectId }) {
      StartFundService.getDataFinance(projectId).then(res => {
        commit('setDataFinance', res.data.data)
      })
    },
    getDataEfek ({ commit }, { projectId }) {
      StartFundService.getDataEfek(projectId).then(res => {
        commit('setDataEfek', res.data.data)
      })
    },
    getDataVenturehub ({ commit }, { projectId }) {
      StartFundService.getDataVenturehub(projectId).then(res => {
        commit('setDataVenturehub', res.data.data)
      })
    },
    updateDataFinance ({ dispatch }, { body, projectId }) {
      return StartFundService.updateDataFinance(body, projectId).then(res => {
        dispatch('checkDraft')
        // dispatch('getDataFinance', { projectId: projectId })
        return res
      })
    },
    updateDataEfek ({ dispatch }, { body, projectId }) {
      return StartFundService.updateDataEfek(body, projectId).then(res => {
        dispatch('checkDraft')
        // dispatch('getDataEfek', { projectId: projectId })
        return res
      })
    },
    updateDataVenturehub ({ dispatch }, { body, projectId }) {
      return StartFundService.updateDataVenturehub(body, projectId).then(res => {
        dispatch('checkDraft')
        // dispatch('getDataVenturehub', { projectId: projectId })
        return res
      }).catch(e => {
        return Promise.reject(e)
      })
    },
    deleteDraft ({ dispatch }, { projectId }) {
      return StartFundService.deleteDraft(projectId).then(res => {
        dispatch('checkDraft')
        dispatch('clearDraftState')
        return res
      }).catch(e => {
        return e
      })
    }
  },
  getters: {
    getHaveDraft (state) {
      return state.haveDraft
    },
    getSteps (state) {
      return state.steps
    },
    getDraftId (state) {
      return state.draftId
    },
    getDataPerusahaan (state) {
      return state.dataPerusahaan
    },
    getDataFinance (state) {
      return state.dataFinance
    },
    getDataEfek (state) {
      return state.dataEfek
    },
    getDataVenturehub (state) {
      return state.dataVenturehub
    }
  }
}

export default startFund
