<template>
  <div class="col-md-5">
    <a-spin :spinning="isLoading">
      <form class="form-sign" ref="formContainer">
        <h3>Masuk</h3>
        <p class="mb-4">
        </p>
        <div class="form-group">
          <label for="">Alamat Email</label>
          <input
            v-model="userEmail"
            type="email"
            class="form-control"
            placeholder="Alamat Email Anda"
          />
          <div v-if="$v.userEmail.$invalid">
            <small v-show="!$v.userEmail.email" id="passwordHelp" class="text-danger">
              Tolong gunakan format email yang benar
            </small>
        </div>
        </div>
        <div class="form-group">
          <label for="">Kata Sandi</label>
          <div class="position-relative">
            <input
              v-model="password"
              :type="isVisible ? 'text' : 'password'"
              class="form-control"
              placeholder="Password"
            />
            <button
              v-if="!isVisible"
              @click="isVisible = true"
              class="btn-eye"
              type="button"
            >
              <img src="@/assets/img/eye.png" />
            </button>
            <button
              v-else
              @click="isVisible = false"
              class="btn-eye"
              type="button"
            >
              <img src="@/assets/img/invisible.png" />
            </button>
          </div>
        </div>
        <div class="form-group">
          <vue-recaptcha
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            sitekey="6LeDZBwbAAAAAL7gqM2Ct0pCK1KLo0L4hp26kux7"
            :loadRecaptchaScript="true"
          ></vue-recaptcha>
          <div v-if="isVerify === false">
            <small id="passwordHelp" class="text-danger">
              Captcha wajib diisi.
            </small>
          </div>
        </div>
        <div class="form-group">
          <div class="d-flex align-items-start mt-4">
            <div class="d-flex checkbox-terms align-items-center">
              <div class="clearfix">
                <input
                  class="styled-checkbox"
                  id="styled-checkbox-1"
                  type="checkbox"
                  @change="checkboxIsChecked"
                  value="remember-me"
                  name="remember-me"
                />
                <label class="mb-0" for="styled-checkbox-1"></label>
              </div>
              <p class="mb-0" style="margin-top: -10px">Ingat saya</p>
            </div>
            <div class="ml-auto">
              <a v-on:click="toForgotPass()" class="forgot-pass"
                >Lupa kata sandi?</a
              >
            </div>
          </div>
        </div>
        <div
          v-if="isFailed"
          class="alert alert-warning mt-4 mb-5 d-flex align-items-start"
          role="alert"
        >
          <img
            src="@/assets/img/error.png"
            class="img-fluid"
            style="max-width: 5%"
          />
          <div class="clearfix">
            <h4>Error Autentikasi</h4>
            <p class="mb-0">Email / Kata sandi salah</p>
          </div>
        </div>
        <div class="form-group">
          <button
            v-on:click="login()"
            class="btn btn-primary my-2 w-100 mt-4"
            type="button"
            :disabled="!isVerify || $v.$invalid"
          >
            Masuk
          </button>
          <button
            v-on:click="toRegister()"
            class="btn btn-outline-success my-2 w-100 mt-2"
            type="button"
          >
            Daftar Akun
          </button>
        </div>
      </form>
    </a-spin>
    <modal-error
      v-if="isError"
      @close="isError = false"
      :message="errorMessage"
    />
  </div>
</template>

<script>
import { Spin } from 'ant-design-vue'
import Vue from 'vue'
import VueRecaptcha from 'vue-recaptcha'
import { required, email } from 'vuelidate/lib/validators'
import ModalError from '../alert/ModalError.vue'
import { authService } from '../../store/services/auth.services'

Vue.use(Spin)

export default {
  name: 'Login',
  components: {
    VueRecaptcha,
    ModalError
  },
  data () {
    return {
      userEmail: '',
      password: '',
      isLoading: false,
      isVisible: false,
      errorMessage: 'eror',
      isVerify: '',
      isFailed: false,
      isError: false,
      rememberMe: false
    }
  },
  validations: {
    userEmail: {
      required,
      email
    },
    password: {
      required
    }
  },
  methods: {
    onVerify: function () {
      this.isVerify = true
    },
    onExpired: function () {
      console.log('Expired')
      this.isVerify = false
      this.$refs.recaptcha.reset()
    },
    login () {
      this.isLoading = true
      this.isFailed = false
      if (!this.$v.$invalid && this.isVerify) {
        const body = {
          email: this.userEmail.toLowerCase(),
          password: this.password,
          rememberMe: this.rememberMe
        }
        authService.login(body).then(
          res => {
            const body = {
              id: res.id,
              email: res.email,
              name: res.name
            }
            this.$store.commit('loginSuccess', res)
            localStorage.setItem('user', JSON.stringify(body))
            localStorage.setItem('bearerToken', res.token)
            this.$cookies.set('token', res.rememberMe, Infinity)
            this.$cookies.set('user', res.token)
          }
        ).catch(err => {
          if (err.response.status === 401) {
            this.isLoading = false
            this.isVerify = false
            this.errorMessage = err.response.data.errorMessages[0].message
            this.openErrorModal()
          } else if (err.response.status === 404) {
            this.isLoading = false
            this.isFailed = true
            this.errorMessage = err.response.data.errorMessages[0].message
            this.openErrorModal()
            this.isVerify = false
          }
          this.$refs.recaptcha.reset()
        }).finally(() => {
          setTimeout(() => {
            this.$refs.recaptcha.reset()
            this.$router.push({ name: 'Dashboard' })
            this.$store.commit('setDashboard', 'Dashboard')
          }, 1000)
        })
      } else {
        this.isLoading = false
        // this.isError = true
        // this.errorMessage = 'Please fill the field & recaptcha'
      }
    },
    checkboxIsChecked (value) {
      if (value) {
        this.rememberMe = true
      } else {
        this.rememberMe = false
      }
    },
    toRegister () {
      this.$router.push({ name: 'Register' })
    },
    toForgotPass () {
      this.$router.push({ name: 'ForgotPassword' })
    },
    openErrorModal () {
      this.isError = true
    }
  },
  watch: {
    isVerify () {
      console.log('recaptcha', this.isVerify)
    }
  }
}
</script>

<style scoped>
.spin-content {
  border: 1px solid hsl(203, 100%, 78%);
  background-color: #e6f7ff;
  padding: 30px;
}
</style>
