<template>
  <div>
    <Header v-if="!user" :isHome="true" isDash />
    <Header1 ref="headers" :isHome="true" v-else />
    <section>
      <div class="home">
        <Banner />
        <div class="container-fluid">
          <!--<Section1 />-->
          <Section2 />
          <!--<Section3 />-->
        </div>
        <!-- <Section4 /> -->
        <!-- <Section5 /> -->
        <div>
          <Section2A />
        </div>
        <Section6 />
        <!--<Disclaimer />-->
      </div>
    </section>
    <Footer />
    <!-- <div
      class="modal fade"
      id="kyc-alert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="kycAlertLabel"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Silahkan Lengkapi KYC Anda</h5>
          </div>
          <div class="modal-body">
            <img src="@/assets/image/information.png" class="img-center" />
            <p class="modal-message">
              Untuk mengakses semua fitur, anda harus mengisi KYC terlebih dahulu.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              style="margin-right: 10px"
              v-on:click="closeModal"
            >
              Lewati
            </button>
            <button v-on:click="toKyc" data-dismiss="modal" class="btn btn-primary">Berikutnya</button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div
      class="modal fade"
      id="waiting-alert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="waitingAlertLabel"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <img src="@/assets/image/information.png" class="img-center" />
            <p class="modal-message">Harap lengkapi profil Anda terlebih dahulu untuk membuka semua akses.</p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              style="margin-right: 10px"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="modal fade"
      id="reject-alert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="waitingAlertLabel"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <img src="@/assets/image/information.png" class="img-center" />
            <p class="modal-message">
              KYC anda telah ditolak, silahkan isi kembali data KYC Anda.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              style="margin-right: 10px"
            >
              Tutup
            </button>
            <button
              v-on:click="toKyc"
              data-dismiss="modal"
              class="btn btn-primary"
            >
              Berikutnya
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal-warning
      :message="'Silahkan isi KYC terlebih dahulu untuk mengakses semua fitur.'"
      :button="true"
      :modalId="'kyc-alert'"
      v-on:clickNext="toKyc" />
    <modal-warning
      :message="'Harap lengkapi profil Anda terlebih dahulu untuk membuka semua akses.'"
      :button="false"
      :modalId="'waiting-alert'"
      v-on:clickNext="window.$('#waiting-alert').modal('hide')" />
    <!-- <modal-topup @showModal2="openModal2" />
    <modal-topup-2
          :topup="dataForModal"
          @status="chekSuccess"
        /> -->
    <modal-success
    v-if="isSuccess"
    @close="isSuccess = false"
    message="Berhasil"
    />
    <modal-error
      v-if="isError"
      @close="isError = false"
      message="Bad Request"
    />
    <!-- <otp-modal
      :types="'verifyDebitRegistration'"
      :otpReferenceId="'otpReferenceId'"
      :userReferenceNumber="'userReferenceNumber'" /> -->
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from '../components/LandingPage/Banner.vue'
// import Section1 from '../components/LandingPage/Section1.vue'
import Section2 from '../components/LandingPage/Section2.vue'
import Section2A from '../components/LandingPage/Section2A.vue'
// import Section3 from '../components/LandingPage/Section3.vue'
// import Section4 from '../components/LandingPage/Section4.vue'
// import Section5 from '../components/LandingPage/Section5.vue'
import Section6 from '../components/LandingPage/Section6.vue'
import Header from '../components/Layout/Header.vue'
import Header1 from '../components/Layout/Header1.vue'
import Footer from '../components/Layout/Footer.vue'
import Vue from 'vue'
import { Col, Row } from 'ant-design-vue'
// import Disclaimer from '../components/LandingPage/Disclaimer/Disclaimer.vue'
import { kycService } from '../store/services/kyc.services'
// import ModalTopup from '../components/Payment/ModalTopup.vue'
// import ModalTopup2 from '../components/Payment/ModalTopup2.vue'
import ModalSuccess from '../components/alert/ModalSuccess.vue'
import ModalWarning from '../components/alert/ModalWarning.vue'
import ModalError from '../components/alert/ModalError.vue'
// import OtpModal from '../components/alert/OtpModal.vue'

Vue.use(Col)
Vue.use(Row)

export default {
  name: 'Home',
  data () {
    return {
      isSuccess: false,
      isError: false,
      dataForModal: '',
      user: {}
    }
  },
  components: {
    Header,
    Header1,
    Banner,
    // Section1,
    Section2,
    // Section3,
    // Section4,
    // Section5,
    Section6,
    // Disclaimer,
    // OtpModal,
    Section2A,
    Footer,
    // ModalTopup,
    // ModalTopup2,
    ModalSuccess,
    ModalWarning,
    ModalError
  },
  mounted () {
    // window.$('#otpModal').modal('show')
    setTimeout(() => {
      if (this.$cookies.isKey('user')) {
        const token = this.$cookies.get('user')
        if (token === '' || token === null) localStorage.removeItem('user')
      } else {
        localStorage.removeItem('user')
      }
      const user = localStorage.getItem('user')
      this.user = user
      if (this.user) {
        kycService.getUser(user.id).then(res => {
          if (res.kycStatus === 'EMPTY') {
            window.$('#kyc-alert').modal('show')
          }
        }).catch(e => {
          if (e.response.data.errorMessage !== null) {
            this.errorMessage = e.response.data.errorMessages[0].errorMessage
          }
          this.isError = true
        })
      } else {
        localStorage.removeItem('user')
        this.$store.dispatch('logout')
        this.$cookies.remove('user')
        this.$cookies.remove('token')
      }
    }, 0)
  },
  computed: {
    users () {
      return localStorage.getItem('user')
    }
  },
  watch: {
    users () {
      this.user = this.users()
    }
  },
  methods: {
    chekSuccess (value) {
      if (value === 'success') {
        this.isSuccess = true
      } else {
        this.isError = true
      }
    },
    // openModal2 (value) {
    //   window.$('#topup-balance-01').modal('hide')
    //   this.dataForModal = value
    //   window.$('#topup-balance-02').modal('show')
    // },
    toKyc () {
      window.$('#kyc-alert').modal('hide')
      this.$router.push({ name: 'Kyc1' })
    },
    closeModal () {
      window.$('#kyc-alert').modal('hide')
    }
  }
}
</script>

<style scoped>
header {
  position: fixed;
  width: 100%;
  background: transparent;
  border: none;
  z-index: 10;
}
.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}
.modal-message {
  font-size: 18px;
  margin: auto;
  font-style: bold;
  margin-top: 20px;
  color: black;
  text-align: center;
}
</style>
