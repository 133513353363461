<template>
  <!-- <div> -->
  <footer class="pt-5 mt-auto">
    <img class="footer-img" src="../../assets/img/footer-map.png" />
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <a href="/" class="mb-3 d-block" style="width: 200px"
            ><img src="@/assets/img/wh-white.svg" class="img-fluid"
          /></a>
          <p>
            PT Wira Global Solusi Tbk<br />
            Holland Village Jakarta,<br />
            Jl Letjen Suprapto Kav 60 Lt 29 #11 Cempaka Putih, 10510<br />
            Jakarta Pusat <br />
          </p>
          <ul class="list-unstyled d-flex align-items-center socmed mt-4"></ul>
        </div>
        <div class="col-md-3">
          <!--
          <h2 class="title">Explore</h2>
          <ul class="list-unstyled footer-link mt-4 mb-0">
            <li>
              <a style="color: #ffffff" href="/list-business">Cari Bisnis</a>
            </li>
            <li>
              <a style="color: #ffffff" href="/e-learning">E-Learning</a>
            </li>
          </ul>
          -->
        </div>
        <div class="col-md-3">
          <h2 class="title">Tentang Kami</h2>
          <ul class="list-unstyled footer-link mt-4 mb-0">
            <li><a style="color: #ffffff" @click="toFaq">FAQ</a></li>
            <li>
              <a style="color: #ffffff" href="https://www.wgshub.com/contacts">Hubungi Kami</a>
            </li>
            <li>
              <a href="/privacy-policy">Kebijakan Privasi</a>.
            </li>
            <li>
              <router-link to="/terms-condition"
                >Syarat dan Ketentuan</router-link
              >
            </li>
          </ul>
        </div>
        <div class="col-md-12">
          <p class="copyright">
            <!-- COPYRIGHT ©2021 WinningHand. ALL RIGHTS RESERVED -->
            Hak Cipta © 2024 PT Wira Global Solusi Tbk. Seluruh Hak Dilindungi Undang-Undang
            <!-- VENTURE HUB -->
          </p>
        </div>
      </div>
    </div>
  </footer>
  <!-- </div> -->
</template>

<script>
import Vue from 'vue'
import { Col, Row, Layout } from 'ant-design-vue'
// import StartFundService from '../../store/services/StartFundService'

Vue.use(Col)
Vue.use(Row)
Vue.use(Layout)

export default {
  name: 'Footer1',
  data () {
    return {
      actions: [
        { type: 'facebook' },
        { type: 'instagram' },
        { type: 'youtube' }
      ]
    }
  },
  computed: {
    user () {
      return this.$store.state.kyc.detailUser
    }
  },
  methods: {
    toFaq () {
      this.$router.push({ name: 'FAQ' })
    },
    toContactUs () {
      this.$router.push({ name: 'contact-us' })
    },
    toPrivacyPolicy () {
      this.$router.push({ name: 'PrivacyPolicy' })
    },
    toStartYourRaises () {
      if (this.user.kycStatus === 'APPROVED') {
        this.$router.push({ name: 'OnlineSubmitWh' })
      }
      /*
      if (this.checkKycStatus() && this.user.kycStatus !== 'EMPTY') {
        StartFundService.getDraftCount().then(res => {
          if (res.data.data.draft) {
            this.$router.push({ name: 'OnlineSubmit' })
          } else {
            this.$router.push({ name: 'StartYourRaises' })
          }
        })
      } else {
        if (this.user.kycStatus === 'EMPTY') {
          window.$('#kyc-alert').modal('show')
        } else {
          window.$('#waiting-alert').modal('show')
        }
      }
      */
    },
    checkKycStatus () {
      if (this.user.statusDataDiri &&
        this.user.statusPekerjaan &&
        this.user.statusBank &&
        this.user.statusPreferensi &&
        this.user.statusDokumen) {
        return true
      } else {
        return false
      }
    },
    toListStartup () {
      this.$router.push({ name: 'ListStartup' })
    }
  },
  created () {
  }
}
</script>

<style scoped>
footer .footer-img {
  position: absolute;
  right: 35%;
}
footer {
  position: relative;
  bottom: 0;
  padding-top: 30px !important;
  height: 100% !important;
}
.copyright {
  margin-top: 10px;
  margin-bottom: 25px;
}
</style>
