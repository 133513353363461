import { rawRequest } from '../http_common'

class CopyDataService {
  checkData (body) {
    return rawRequest.post('/user/copy-data/check-data', body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  pickCopyData (body) {
    return rawRequest.post('/user/copy-data/use-data', body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }
}

export default new CopyDataService()
