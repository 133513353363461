import { rawRequest } from '../http_common.js'

class InvestService {
  getInvests (params) {
    return rawRequest.get('/invest/', params)
  }

  getInvestsByUserId (id, params) {
    return rawRequest.get(`/invest/user/${id}`, { params })
  }

  getInvest (id) {
    return rawRequest.get(`/invest/${id}`).then(res => {
      const data = res.data.data
      return data
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  submitInvest (body) {
    return rawRequest.post('/invest/submit', body).then(res => {
      const data = res
      console.log(res)
      return data
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  refundInvestment (noTransaksi) {
    return rawRequest.post(`/invest/refund/${noTransaksi}`)
      .then(res => {
        console.log(res)
        return res.data
      }).catch(e => {
        return Promise.reject(e)
      })
  }

  submitInvestMidtrans (body) {
    return rawRequest.post('/invest/midtrans', body)
      .then(res => {
        console.log(res)
        return res.data.data
      }).catch(e => {
        return Promise.reject(e)
      })
  }
}

export default new InvestService()
