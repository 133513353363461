import { rawRequest } from '../http_common'

class TermsAndCoditionService {
  getData () {
    return rawRequest.get('/terms-condition').then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }
}

export default new TermsAndCoditionService()
