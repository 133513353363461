import { rawRequest } from '../http_common'

class DanamonService {
  debitRegistration (body, email) {
    return rawRequest.post(`/danamon/debitRegistration/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  verifyDebit (body, email) {
    return rawRequest.post(`/danamon/verifyDebit/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  holdBalanceOtp (body, email) {
    return rawRequest.post(`/danamon/holdBalanceOtp/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  verifyHoldBalance (body, email) {
    return rawRequest.post(`/danamon/verifyHoldBalance/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  retryOtp (body, email) {
    return rawRequest.post(`/danamon/retryOtp/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  inquiryVid (body, email) {
    return rawRequest.post(`/danamon/inquiryVid/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e.response)
      return Promise.reject(e)
    })
  }

  fundDisbursement (body, email) {
    return rawRequest.post(`/danamon/fundDisbursement/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  checkInquiryBalance (body, email) {
    return rawRequest.post(`/danamon/checkInquiryBalance/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  holdbalance (body, email) {
    return rawRequest.post(`/danamon/holdBalance/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  unregisterDebit (body, email) {
    return rawRequest.post(`/danamon/unregisterDebit/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  unholdBalance (body, email) {
    return rawRequest.post(`/danamon/unholdBalance/${email}`, body).then(res => {
      return res
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  verifyBalanceInquiry (body, email) {
    return rawRequest.post(`/danamon/verifyBalanceInquiry/${email}`, body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }
}

export default new DanamonService()
