import axios from 'axios'
import { rawRequest, multipartRequest } from '../http_common'
const url = process.env.VUE_APP_BASE_URL

export const authService = {
  login,
  logout,
  register,
  forgotPassword,
  resetPassword,
  checkResetToken,
  suspendAcc,
  persistentLogin,
  verifyAcc,
  deleteAcc
}

const request = axios.create({
  baseURL: url,
  headers: { 'Content-Type': 'application/json' }
})

function register (body) {
  return multipartRequest.post('/auth/register', body).then(res => {
    const data = res
    return data
  }).catch(e => {
    return Promise.reject(e)
  })
}

function suspendAcc (id, body) {
  return rawRequest.post(`/user/suspend/${id}`, body).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}

function deleteAcc (id) {
  return rawRequest.post(`/user/deleted-permanen/${id}`).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}

function verifyAcc (params) {
  return rawRequest.get('/auth/verify', { params }).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}

function login (body) {
  return request.post('/auth/login', body).then(res => {
    const data = res.data.data
    return data
  }).catch(e => {
    return Promise.reject(e)
  })
}

function logout () {
  localStorage.removeItem('user')
  localStorage.removeItem('bearerToken')
}

function forgotPassword (body) {
  return request.post('/auth/forgot-password', body).then(res => {
    const data = res.data.data
    return data
  }).catch(e => {
    return Promise.reject(e)
  })
}

function resetPassword (body) {
  return request.post('/auth/reset-password', body).then(res => {
    const data = res
    return data
  }).catch(e => {
    return Promise.reject(e)
  })
}

function checkResetToken (token) {
  return request.get('/auth/check-reset-token', {
    params: {
      token: token
    }
  }).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}

function persistentLogin (body) {
  return request.post('/auth/cookie-login', body).then(res => {
    return res.data.data
  }).catch(e => {
    return Promise.reject(e)
  })
}
