<template>
      <router-view />
</template>

<script>
import Vue from 'vue'
import store from './store/store.js'
import 'vue-loading-overlay/dist/vue-loading.css'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import SettingService from '../src/store/services/SettingService.js'
import { authService } from '../src/store/services/auth.services'
// import SockJS from 'sockjs-client'
// import Stomp from 'webstomp-client'
import { kycService } from './store/services/kyc.services'

Vue.use(VueToast)
// import Header from '@/components/Layout/Header.vue'
// import Footer from '@/components/Layout/Footer.vue'
export default {
  // el: '#app',
  store,
  name: 'App',
  data () {
    return {
      connected: false,
      token: ''
    }
  },
  beforeCreate () {
    if (localStorage.getItem('user') !== null) {
      const cookies = this.$cookies.get('token')
      if (cookies !== null) {
        const body = {
          rememberMe: this.$cookies.get('token')
        }
        authService.persistentLogin(body).then(res => {
          const body = {
            name: res.name,
            id: res.id,
            email: res.email
          }
          localStorage.setItem('user', JSON.stringify(body))
          this.$cookies.set('user', res.token)
          // this.connect(res.token.replaceAll('Bearer'))
        })
      }
    }
    // this.getUser()
  },
  beforeMount () {
    this.connect()
    SettingService.getSetting().then(res => {
      if (res.maintenance === true) {
        this.$router.push({ name: 'ErrorPageInvalid' })
      } else {
        if (this.$router.currentRoute.name === 'ErrorPageInvalid') {
          this.$router.push({ name: 'Home' })
          this.$store.dispatch('changeTimeSecondaryMarket1', { val: res.tanggalPasarSekunderPertama })
          this.$store.dispatch('changeTimeSecondaryMarket2', { val: res.tanggalPasarSekunderKedua })
          this.$store.dispatch('changeFairPriceSecondaryMarket', { val: res.hargaWajar })
        } else {
          // this.$router.
          this.$store.dispatch('changeTimeSecondaryMarket1', { val: res.tanggalPasarSekunderPertama })
          this.$store.dispatch('changeTimeSecondaryMarket2', { val: res.tanggalPasarSekunderKedua })
          this.$store.dispatch('changeFairPriceSecondaryMarket', { val: res.hargaWajar })
        }
      }
    }).catch(e => {
      this.$router.push({ name: 'ErrorPageInvalid' })
    })
    this.getUser()
  },
  mounted () {
    // this.connect()
  },
  watch: {
    connected () {
      if (!this.connected) {
        this.connect()
      }
    }

  },
  methods: {
    connect () {
      /*
      const cookiesUser = this.$cookies.get('user')
      if (cookiesUser) {
        const token = cookiesUser.replace('Bearer ', '')
        const sessionId = Math.random().toString(36).substring(3, 9)
        this.socket = new SockJS('https://venturehub-admin-api.stagingapps.net/ws/connect?authorization=' + token, [], {
          sessionId: () => { return sessionId }
        })
        this.stompClient = Stomp.over(this.socket)
        this.stompClient.connect(
          {},
          (frame) => {
            this.connected = true
            this.stompClient.subscribe('/websocket/queue/topic/stockprice', (tick) => {
              const obj = JSON.parse(tick.body)
              this.$store.dispatch('secondaryMarketTertinggi', { val: obj.maxRatio })
              this.$store.dispatch('secondaryMarketTerendah', { val: obj.minRatio })
              this.$store.dispatch('secondaryMarketPembuka', { val: obj.hargaPembuka })
              this.$store.dispatch('secondaryMarketPenutup', { val: obj.hargaPenutup })
              this.$store.dispatch('secondaryMarketPersenTinggi', { val: obj.persenTertinggi })
              this.$store.dispatch('secondaryMarketPersenRendah', { val: obj.persenTerendah })
            })
            this.stompClient.subscribe('/secured/user/topic/stockprice/failed' + '-user' + sessionId, (tick) => {
            })
          },
          (_) => {
            this.connected = false
          }
        )
      }
      */
    },
    getUser () {
      const user = this.$store.state.auth.user
      window.addEventListener('scroll', this.updateScroll)
      if (user.id) {
        kycService.getUser(user.id).then(res => {
          // this.user = res
          // if (res.isDebitActive === true) this.isUserDebit = res.isDebitActive
          this.$store.commit('getUser', res)
          // this.$store.dispatch('getUserDebit', { email: res.email })
        }).catch(_ => {
        }).finally(() => {
        })
      }
    }
  }
}
</script>

<style>
</style>
