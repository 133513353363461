import { multipartRequest, rawRequest } from '../http_common.js'

class startFundService {
  addFund (id, data) {
    // console.log(data)
    return multipartRequest.post(`project/create-project/${id}`, data, {
      maxContentLength: 100000000,
      maxBodyLength: 100000000
    })
      .then(res => {
        // console.log(data.documents)
        // console.log(res)
        return res
      }).catch(e => {
        // console.log(e)
        return Promise.reject(e)
      })
  }

  uploadDocs (data) {
    return multipartRequest.post('project/create-project/upload-docs', data)
      .then(res => {
        // console.log(res)
        return res
      }).catch(e => {
        // console.log(e.response)
        return Promise.reject(e)
      })
  }

  uplodCompanyDocs (data, projectId) {
    return rawRequest.post(`/project/company-document/inserts/${projectId}`, data)
      .then(res => {
        // console.log(res)
        return res
      }).catch(e => {
        // console.log(e.response)
        return Promise.reject(e)
      })
  }

  updateDataPerusahaan (body, projectId) {
    return rawRequest.post(`/project/fund-rising/perusahaan/${projectId}`, body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  updateDataFinance (body, projectId) {
    return multipartRequest.post(`/project/fund-rising/financial/${projectId}`, body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  updateDataEfek (body, projectId) {
    return rawRequest.post(`/project/fund-rising/efek/${projectId}`, body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  updateDataVenturehub (body, projectId) {
    return rawRequest.post(`/project/fund-rising/venturehub/${projectId}`, body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  getDataPerusahaan (projectId) {
    return rawRequest.get(`/project/fund-rising/perusahaan/${projectId}`).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  getDataFinance (projectId) {
    return multipartRequest.get(`/project/fund-rising/financial/${projectId}`).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  getDataEfek (projectId) {
    return rawRequest.get(`/project/fund-rising/efek/${projectId}`).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  getDataVenturehub (projectId) {
    return rawRequest.get(`/project/fund-rising/venturehub/${projectId}`).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  getDraftCount () {
    return rawRequest.get('/project/fund-rising/get-draft').then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }

  deleteDraft (projectId) {
    return rawRequest.post(`/project/fund-rising/delete-draft/${projectId}`).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }
}

export default new startFundService()
