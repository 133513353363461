import { multipartRequest, rawRequest } from '../http_common.js'
import axios from 'axios'
const url = process.env.VUE_APP_BASE_URL

export const kycService = {
  update,
  getUser,
  getTermsCon,
  changePassword,
  uploadPp,
  checkInvestLimit,
  checkKyc,
  submitKyc,
  checkAvailableEmail
}

const request = axios.create({
  baseURL: url,
  headers: { 'Content-Type': 'application/json' }
})

function update (data) {
  const user = JSON.parse(localStorage.getItem('user'))
  const id = user.id
  return multipartRequest.post(`user/update/${id}`, data)
    .then(res => {
      const data = res.data.data
      return data
    }).catch(e => {
      return Promise.reject(e)
    })
}

function submitKyc (data) {
  const user = JSON.parse(localStorage.getItem('user'))
  const id = user.id
  return multipartRequest.post(`user/kyc/${id}`, data)
    .then(res => {
      const data = res.data.data
      return data
    }).catch(e => {
      return Promise.reject(e)
    })
}

function uploadPp (data) {
  const user = JSON.parse(localStorage.getItem('user'))
  const id = user.id
  return multipartRequest.post(`user/changePp/${id}`, data)
    .then(res => {
      return res
    }).catch(e => {
      console.log('upload image error', e.response)
      return Promise.reject(e)
    })
}

function getTermsCon () {
  return request.get('terms-condition')
    .then(res => {
      const data = res.data.data
      return data
    }).catch(e => {
      return Promise.reject(e)
    })
}

function getUser () {
  const user = JSON.parse(localStorage.getItem('user'))
  return rawRequest.get(`user/${user.id}`).then(res => {
    const data = res.data.data
    return data
  }).catch(e => {
    return Promise.reject(e)
  })
}

function changePassword (body) {
  const user = JSON.parse(localStorage.getItem('user'))
  const id = user.id
  return rawRequest.post(`user/change-password/${id}`, body).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}

function checkInvestLimit () {
  const user = JSON.parse(localStorage.getItem('user'))
  const id = user.id
  return rawRequest.get(`user/check-limit-invest/${id}`).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}

function checkKyc () {
  const user = JSON.parse(localStorage.getItem('user'))
  const id = user.id
  return rawRequest.get(`user/check-kyc/${id}`).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}

function checkAvailableEmail (email) {
  return rawRequest.get(`user/check-email-available?email=${encodeURIComponent(email)}`).then(res => {
    return res
  }).catch(e => {
    return Promise.reject(e)
  })
}
