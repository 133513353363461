<template>
  <div class="top-banner" :style="homepageBanner">
    <div class="container-fluid h-100">
      <div
        class="
          d-flex
          align-items-start
          justify-content-center
          h-100
          flex-column
        "
      >
        <h2 class="mb-4" style="text-align: left;">
          Earn your <span>#winninghand</span><br/>
          in the game of business
        </h2>
        <p class="mb-5">
          Partner with our business accelerator today
        </p>
        <a v-if="!isloggedUser" @click="toRegister" class="btn btn-primary">REGISTER</a>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, Row } from 'ant-design-vue'
import AppSettingService from '../../store/services/AppSettingService.js'

Vue.use(Col)
Vue.use(Row)

export default {
  name: 'Banner',
  data () {
    return {
      banner: ''
    }
  },
  computed: {
    homepageBanner () {
      if (this.banner !== null && this.banner !== '' && this.banner !== 'undefined') {
        console.log('true')
        return {
          backgroundImage: `url(${this.banner})`
        }
      } else {
        return {
          backgroundImage: '@/assets/img/home-banner2.jpg'
        }
      }
    },
    isloggedUser () {
      const user = localStorage.getItem('user')
      return (user != null)
    }
  },
  methods: {
    toListStartup () {
      this.$router.push({ name: 'ListStartup' })
    },
    toRegister () {
      this.$router.push({ name: 'Register' })
    }
  },
  mounted () {
    AppSettingService.getBannerHomepage().then(res => {
      console.log(res.data.data)
      this.banner = res.data.data
    }).catch(e => {
      console.error(e.response)
    })
  }
}
</script>

<style scoped>
.top-banner:before {
  /*content: "";*/
  /* background: rgba(0, 0, 0, 0.4);*/
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.top-banner {
  height: 655px;
  background: url(../../assets/img/home-banner2.png) no-repeat;
  padding-bottom: 75px;
  width: 100%;
  background-size: cover;
  position: relative;
}
.top-banner > .container-fluid {
  position: relative;
}
.top-banner > .container-fluid {
  position: relative;
}
.top-banner h2 {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 58px;
  color: #ffffff;
  margin-top: 30px;
}
.top-banner p {
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 34px;
  color: #ffffff;
  font-family: "Inter", sans-serif;
}
</style>
