import InvestService from '../services/InvestService'

const invest = {
  state: {
    isLoading: false,
    detailInvest: {},
    submitInvest: {},
    submitInvestId: '',
    investId: ''
  },
  mutations: {
    changeSubmitInvestId (state, payload) {
      state.submitInvestId = payload
    },
    changeInvestId (state, payload) {
      state.investId = payload
    },
    loading (state, status) {
      state.isLoading = status
    },
    getInvest (state, payload) {
      state.detailInvest = payload
    },
    clearInvest (state) {
      state.detailInvest = {}
    },
    submitInvest (state, payload) {
      state.submitInvest = payload
    },
    clearSubmit (state) {
      state.submitInvest = {}
    }
  },
  actions: {
    getInvest ({ commit }, { id }) {
      commit('loading', true)
      InvestService.getInvest(id)
        .then(
          res => {
            commit('getInvest', res)
            commit('loading', false)
          },
          error => {
            console.log(error)
            commit('clearInvest')
            commit('loading', false)
          }
        )
    },
    submitInvest ({ commit }, { body }) {
      commit('loading', true)
      InvestService.submitInvest(body)
        .then(
          res => {
            commit('loading', false)
            commit('submitInvest', res.data.data)
          },
          error => {
            console.log(error)
            commit('loading', false)
            commit('clearSubmit')
          }
        )
    }
  }
}

export default invest
