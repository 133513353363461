import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import kyc from './modules/kyc'
import project from './modules/project'
import startFund from './modules/start_fund'
import withdraw from './modules/withdraw'
import topup from './modules/topup'
import transaction from './modules/transaction'
import onboardingDanamon from './modules/onboarding_danamon'
import invest from './modules/invest'
import danamon from './modules/danamon'
import createPersistedState from 'vuex-persistedstate'
import userDebit from './modules/user_debit'
import secondaryMarket from './modules/secondaryMarket'
import dashboardTabs from './modules/dashboard_tab'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    errorState: {},
    isError: false
  },
  mutations: {
    onErrorRequest (state, payload) {
      state.errorState = payload
      state.isError = true
    },
    clearError (state) {
      state.errorState = {}
      state.isError = false
    }
  },
  plugins: [createPersistedState()],
  actions: {
    updateModalState ({ commit }, { val }) {
      commit('updateModalState', val)
    },
    clearError ({ commit }) {
      commit('clearError')
    }
  },
  modules: {
    auth,
    kyc,
    project,
    invest,
    transaction,
    startFund,
    topup,
    withdraw,
    danamon,
    userDebit,
    onboardingDanamon,
    secondaryMarket,
    dashboardTabs
  }
})
