import { kycService } from '../services/kyc.services'
const kyc = {
  state: {
    detailUser: {},
    update: {},
    isLoading: false,
    termsCon: {},
    isError: false,
    changePassword: {},
    pp: {}
  },
  mutations: {
    loading (state, status) {
      state.isLoading = status
    },
    updateUser (state, payload) {
      state.update = payload
    },
    clearUpdate (state) {
      state.update = {}
    },
    clearUser (state) {
      state.detailUser = {}
    },
    changePassword (state, payload) {
      state.changePassword = payload
    },
    getUser (state, detailUser) {
      state.detailUser = detailUser
    },
    getTermsCon (state, terms) {
      state.termsCon = terms
    },
    changePp (state, payload) {
      state.pp = payload
    },
    clearPp (state) {
      state.pp = {}
    }
  },
  actions: {
    updateUser ({ commit }, { data }) {
      commit('loading', true)
      kycService.update(data)
        .then(
          res => {
            commit('updateUser', res)
            commit('loading', false)
            commit('clearError')
          },
          error => {
            console.log(error)
            commit('clearUser')
            commit('updateUser', true)
            commit('onErrorRequest', error.response)
            commit('loading', false)
          }
        )
    },
    updateKyc2 ({ commit }, { data }) {
      kycService.submitKyc(data).then(res => {
        commit('updateUser', res)
        commit('loading', false)
        commit('clearError')
      },
      error => {
        console.log(error)
        commit('clearUser')
        commit('updateUser', true)
        commit('onErrorRequest', error.response)
        commit('loading', false)
      })
    },
    clearUpdate ({ commit }) {
      commit('clearUpdate')
    },
    changePassword ({ commit }, { body }) {
      commit('loading', true)
      kycService.changePassword(body)
        .then(
          res => {
            commit('changePassword', res)
            commit('loading', false)
            commit('clearError')
          },
          error => {
            console.log(error)
            commit('changePassword', error)
            commit('onErrorRequest', error.response)
            commit('loading', false)
          }
        )
    },
    uploadPp ({ commit }, { body }) {
      commit('loading', true)
      kycService.uploadPp(body)
        .then(
          res => {
            commit('changePp', res)
            commit('loading', false)
            commit('clearError')
          },
          error => {
            console.log(error)
            commit('onErrorRequest', error.response)
            commit('clearPp')
            commit('loading', false)
          }
        )
    },
    getUser ({ commit }) {
      console.log('hit get user store')
      kycService.getUser()
        .then(
          res => {
            commit('getUser', res)
          },
          error => {
            console.log(error)
            commit('clearUser')
          }
        )
    },
    getTermsCon ({ commit }) {
      kycService.getTermsCon()
        .then(
          res => {
            console.log(res)
            commit('getTermsCon', res)
          }
        )
    }
  }
}

export default kyc
