// import VueCookies from 'vue-cookies'
export function authHeader () {
  const token = localStorage.getItem('bearerToken')
  if (token) {
    return { Authorization: token, 'Content-Type': 'application/json' }
  } else {
    return {}
  }
  /*
  if (VueCookies.isKey('user')) {
    const token = VueCookies.get('user')
    return { Authorization: token, 'Content-Type': 'application/json' }
  } else {
    return {}
  }
    */
}

export function multipartHeader () {
  const token = localStorage.getItem('bearerToken')
  if (token) {
    return { Authorization: token, 'Content-Type': 'multipart/form-data' }
  } else {
    return {}
  }
  /*
  console.log()
  if (VueCookies.isKey('user')) {
    const token = VueCookies.get('user')
    return { Authorization: token, 'Content-Type': 'multipart/form-data' }
  } else {
    return {}
  }
    */
}
