<template>
    <div class="modal fade" id="modalTermsCondition" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content" style="height: 90vh;">
            <div class="modal-header">
              <p class="modal-title fw-bold" id="exampleModalLongTitle">Terms And Conditions</p>
              <button @click="$emit('close')" type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body" style="height: 80vh;
            overflow-y: auto;">
                <!-- <h4 style="text-align: left;">Terms &amp; Conditions</h4>
                    <p>Situs WinningHand merupakan situs milik dari PT WinningHand</p>
                    <p>
                    Syarat dan ketentuan umum ini mengatur hak dan kewajiban yang mengikat secara hukum terhadap Pengunjung dan
                    Pengguna untuk mengakses, menggunakan dan mengunjungi setiap dan seluruh laman situs (<i>website</i>) dan
                    layanan yang terdapat pada situs www.venturehub.id ("Situs WinningHand"). Dengan mengunjungi dan/atau
                    menggunakan situs WinningHand, maka baik pengunjung maupun pengguna dinyatakan telah memahami dan menyepakati
                    semua isi dalam syarat dan ketentuan di bawah ini.
                    </p>
                    <p>
                    PT Wira Global Solusi Tbk merupakan penyelenggara layanan urun dana melalui penawaran saham berbasis
                    teknologi informasi (<i>equity crowdfunding</i>) berdasarkan Peraturan POJK 57 /POJK.04/2020 (selanjutnya
                    disebut “POJK”)
                    </p>
                    <p>
                    Situs WinningHand merupakan <i>platform</i> yang menyediakan layanan urun dana melalui penawaran saham
                    berbasis teknologi informasi, yang mempertemukan pihak yang melakukan pembelian saham penerbit (selanjutnya
                    disebut “Pemodal”) dengan badan hukum Indonesia berbentuk perseroan terbatas yang menawarkan saham melalui
                    Penyelenggara (selanjutnya disebut “Penerbit”), untuk selanjutnya Pemodal dan Penerbit selanjutnya disebut
                    “Pengguna”.
                    </p>
                    <p>
                    Situs WinningHand membantu para Penerbit untuk mendapatkan dana dalam rangka pengembangan usaha (<i>business
                        expansion</i>) secara cepat dan efisien, dengan cara menawarkan sejumlah saham miliknya kepada Pemodal,
                    tanpa melakukan Penawaran Umum Perdana (<i>go public</i>) atau pinjaman ke bank.
                    </p>
                    <p>
                    Pemodal dapat berinvestasi pada usaha-usaha yang sudah terdaftar di Situs WinningHand bersama dengan Pemodal
                    lain. WinningHand akan menyediakan informasi mengenai usaha yang akan didanai, lokasi, sejarah perusahaan
                    Penerbit, kondisi usaha, termasuk data lampau (<i>historical data</i>) atas perkembangan usaha, tujuan
                    pengumpulan dana, serta data laporan keuangan sebagai bahan rujukan dan pertimbangan Pemodal sebelum
                    berinvestasi melalui WinningHand.
                    </p>
                    <h5> Biaya Operasional dan Biaya Tambahan </h5>
                    <p>
                    1. Penyelenggara akan membebankan biaya manajemen sebesar 5% (lima persen) dari hasil keuntungan yang
                    diterima oleh Penerbit dan/atau Pemodal sebelum pajak, yang akan dikenakan pada saat pembagian dividen
                    dan/atau profit dilakukan. Biaya manajemen ini merupakan biaya atas pengelolaan dan administrasi kegiatan
                    usaha Penerbit, termasuk namun tidak terbatas pada penyampaian laporan keuangan dan/atau pembagian hasil
                    usaha Penerbit (pembagian dividen dan/atau profit) yang dilakukan oleh Penyelenggara kepada Pemodal.
                    </p>
                    <p>
                    2. Dalam hal terdapat pajak, retribusi dan/atau pungutan lainnya yang dikenakan oleh peraturan
                    perundang-undangan yang berlaku terhadap Dana yang diperoleh dari Penawaran Saham, maka Penyelenggara akan
                    menetapkan biaya tambahan sesuai dengan besaran sebagaimana ditentukan peraturan perundang-undangan yang
                    bersangkutan.
                    </p>
                    <h5> Pengajuan Pendanaan </h5>
                    <p>
                    Penerbit dapat mengajukan permohonan pendanaan usaha, baik yang sudah berjalan maupun usaha baru yang akan
                    dibentuk (untuk selanjutnya disebut “Pendanaan Usaha”) pada situs WinningHand. Usaha yang akan didanai
                    melalui layanan urun dana harus berbentuk badan hukum, baik perseroan terbatas maupun koperasi. Penawaran
                    Saham dilakukan melalui situs WinningHand, paling lama 90 (sembilan puluh) hari (“Masa Penawaran”) setelah
                    Penyelenggara memuat usaha Penerbit yang akan didanai melalui layanan urun dana. Penghimpunan dana oleh
                    Penerbit melalui layanan urun dana dalam jangka waktu 12 (dua belas) bulan maksimum sebesar
                    Rp.10.000.000.000,- (sepuluh milyar Rupiah) dan hanya dapat dilakukan dalam 1 (satu) kali penawaran melalui
                    1 (satu) Penyelenggara dalam waktu yang bersamaan. Penerbit dapat menetapkan minimum jumlah dana yang harus
                    terhimpun dengan menyampaikan rencana penggunaan dan sumber pendanaan lain untuk Pendanaan Usaha kepada
                    Penyelenggara. Penerbit dilarang mengubah jumlah dana yang harus terhimpun jika masa penawaran sudah
                    berjalan.
                    </p>
                    <p>
                    Apabila jumlah minimum dana yang harus terhimpun tidak terpenuhi, maka Penawaran Saham melalui layanan urun
                    dana tersebut menjadi batal demi hukum. Untuk itu, Penyelenggara akan mengembalikan dana beserta seluruh
                    manfaat yang timbul dari dana tersebut selama dalam rekening penampung (escrow account) secara proporsional
                    kepada Pemodal selambat-lambatnya 2 (dua) hari kerja setelah Penawaran Saham batal demi hukum.
                    </p>
                    <h5> Pemodal </h5>
                    <p>
                    Pihak yang memiliki kemampuan analisis risiko terhadap saham penerbit dan memenuhi kriteria pemodal
                    sebagaimana diatur dalam POJK dan berkeinginan untuk melakukan penyertaan saham dan/atau Pembelian Saham
                    (Pemodal) dapat membeli saham melalui layanan urun dana dengan membuat akun dan mengisi data-data yang
                    diperlukan dan melakukan penyetoran saham yang akan dibeli ke dalam rekening penampung (escrow account) yang
                    dikelola oleh Penyelenggara serta memberi kuasa kepada Penyelenggara untuk mewakili Pemodal sebagai pemegang
                    saham Penerbit termasuk untuk menghadiri rapat umum pemegang saham Penerbit dan sebagai kuasa Pemodal dalam
                    melakukan penandatanganan dokumen terkait lainnya.
                    </p>
                    <h5> Penyelenggara </h5>
                    <p>
                    Penyelenggara dapat melakukan pembatalan atas pembelian saham milik Penerbit oleh Pemodal melalui layanan
                    urun dana apabila ditemukan hal-hal yang bertentangan dengan hukum, Penyelenggara akan mengembalikan dana
                    milik pemodal ke dalam rekening yang ditunjuk oleh Pemodal.
                    </p>
                    <p>
                    Setelah dana yang diperlukan untuk Pendanaan Usaha telah mencukupi, maka Penyelenggara akan menyatakan bahwa
                    Pendanaan Usaha tersebut telah "Terpenuhi". Selanjutnya Penyelenggara akan menyerahkan dana dari Pemodal
                    kepada Penerbit selambat-lambatnya 21 (dua puluh satu) hari kerja setelah berakhirnya Masa Penawaran Saham.
                    Baik dalam hal Pendanaan Usaha melalui Pengambilalihan/take over maupun grand opening, Penerbit diwajibkan
                    menyerahkan saham kepada Penyelenggara untuk didistribusikan kepada Pemodal paling lambat 5 (lima) hari
                    kerja setelah Penerbit menerima dana Pemodal dari Penyelenggara dan pendistribusian saham dapat dilakukan
                    secara elektronik melalui penitipan kolektif pada kustodian paling lambat 10 (sepuluh) hari kerja.
                    </p>
                    <h5> Penerbit </h5>
                    <p>
                    Penerbit adalah badan hukum yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara
                    Kesatuan Republik Indonesia. Penerbit dalam menjalankan kegiatan usahanya telah memiliki setiap perizinan
                    yang dipersyaratkan dan perizinan tersebut, sampai ini masih berlaku dan/atau tidak sedang ditangguhkan oleh
                    pihak yang berwenang.
                    </p>
                    <p>
                    Penerbit wajib bersedia untuk menandatangani perjanjian-perjanjian lain terkait dan sehubungan dengan
                    kegiatan layanan urun dana dan/atau penghimpunan dana melalui Penyelenggara, termasuk namun tidak terbatas
                    pada perjanjian waralaba, perjanjian sewa lahan dan/atau tempat usaha maupun perjanjian-perjanjian turunan
                    lainnya dari layanan urun dana yang diselenggarakan oleh Penyelenggara.
                    </p>
                    <h5> Share Dividen </h5>
                    <p>
                    Laba bersih dari hasil usaha Penerbit (selanjutnya disebut “Dividen”) akan dibagikan kepada pemegang saham
                    sesuai dengan jumlah kepemilikan sahamnya pada perusahaan Penerbit. Pembagian laba bersih kepada Pemodal
                    dilakukan pada setiap akhir tahun buku berdasarkan rekomendasi dari Direksi Perseroan dengan persetujuan
                    dari rapat umum pemegang saham Penerbit.
                    </p>
                    <p>
                    Terlepas dari yang sudah ditentukan di atas, berdasarkan rekomendasi dari Direksi Perseroan dengan
                    persetujuan dari rapat umum pemegang saham Penerbit, Penerbit dapat melakukan pembagian dividen pada masa
                    tahun buku perseroan (selanjutnya disebut “Dividen Interim”) yang akan dilakukan oleh Penyelenggara melalui
                    Saldo WinningHand. Pendistribusian Dividen Interim melalui Saldo WinningHand dilakukan Pemodal dengan
                    menyampaikan instruksi pencairan Saldo WinningHand dengan mencantumkan jumlah nominal yang akan dilakukan
                    pencairan.
                    </p>
                    <h5> Saldo WinningHand </h5>
                    <p>
                    Setiap dana yang masuk ke dalam Saldo WinningHand dapat ditarik sewaktu-waktu oleh Pemodal dengan cara
                    menyampaikan instruksi pencairan dana melalui platform yang disediakan oleh Penyelenggara/WinningHand,
                    Penyelenggara akan melakukan pemindahbukuan Saldo WinningHand ke dalam rekening yang ditunjuk oleh Pemodal
                    selambat-lambatnya 3 (tiga) hari kerja.
                    </p>
                    <h5> Penonaktifan, Pemutusan dan Penghapusan Akun </h5>
                    <p>
                    Pengguna Platform berhak untuk mengajukan permohonan penonaktifan dan/atau pemutusan dan penghapusan Akun
                    yang terdaftar atas namanya pada Platform kepada WinningHand dengan mengirim email ke support@venturehub.id
                    </p>
                    <p>
                    Penonaktifan dan/atau pemutusan dan penghapusan Akun dari Pengguna Platform dilakukan dengan memperhatikan
                    kewajiban WinningHand untuk melakukan penyimpanan data pribadi sesuai batas waktu yang ditentukan dalam
                    peraturan perundang-undangan yang berlaku sejak tanggal penonaktifan dan/atau pemutusan dan penghapusan Akun
                    tersebut.
                    </p>
                    <p>
                    Penyelenggara Platform berhak untuk melakukan penonaktifan dan/atau pemutusan dan penghapusan Akun dari
                    Pengguna Platform apabila terbukti melanggar Syarat dan Ketentuan ini dan/atau perundang-undangan yang
                    berlaku di negara Indonesia
                    </p>
                    <h5> Pengecualian dan Pelepasan Tanggung jawab </h5>
                    <p>
                    WinningHand akan memberikan upaya terbaiknya untuk setiap penyelesaian masalah terhadap segala sengketa yang
                    timbul karena janji-janji Penerbit terhadap Pemodal sesuai peraturan perundang-undangan yang berlaku.
                    </p>
                    <p>
                    Apabila Dana telah diberikan oleh Pemodal kepada Penerbit dan/atau pemegang saham dari Penerbit lalu terjadi
                    peristiwa seperti pencurian, penggelapan, atau tindakan apapun yang menyebabkan kehilangan Dana dari Pemodal
                    pada Platform, maka Penyelenggara akan memberikan upaya terbaiknya untuk membantu Pemodal dalam
                    menyelesaikan permasalahan tersebut dan Pemodal membebaskan Penyelenggara dari segalam macam tuntutan.
                    </p>
                    <p>
                    Dalam keadaan apapun, Pengguna Platform akan membayar kerugian kepada Penyelenggara Platform dan/atau
                    menghindarkan WinningHand (termasuk petugas, direktur, karyawan, agen, dan lainnya) dari setiap biaya
                    kerugian apapun, kehilangan, pengeluaran atau kerusakan yang berasal dari tuntutan atau klaim pihak ketiga
                    yang timbul dari pelanggaran Pengguna Platform terhadap Syarat dan Ketentuan, dan/atau pelanggaran terhadap
                    hak dari pihak ketiga.
                    </p>
                    <p>
                    Apabila di kemudian hari Anda sebagai pengguna Platform menemukan atau mendapati bahwa terdapat Penawaran
                    Saham yang mencurigakan atau berindikasi penipuan, Anda setuju untuk membantu dan bekerjasama dengan
                    WinningHand untuk melaporkan dan ikut serta membantu menyelesaikan perkara tersebut.
                    </p>
                    <p>
                    WinningHand tidak memberikan jaminan dan/atau ganti rugi dalam bentuk apapun atas penggalangan dana yang
                    dilaksanakan di situs kami.
                    </p>
                    <h5> Syarat Pengguna Platform </h5>
                    <p>
                    Pengguna Platform adalah :
                    </p>
                    <ul>
                    <li>
                        <p>
                        Badan Hukum yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan Negara Kesatuan
                        Republik Indonesia atau seseorang yang berusia minimum 18 tahun atau wajib dengan pengawasan dan
                        persetujuan dari orang tua/wali yang sah apabila berusia 13 sampai 17 tahun;
                        </p>
                    </li>
                    <li>
                        <p>
                        Seseorang yang cakap di mata hukum sehingga bertanggung jawab atas segala tindakan ataupun kelalaian
                        apabila melanggar syarat dan ketentuan ini;
                        </p>
                    </li>
                    <li>
                        <p>
                        Penyelenggara adalah badan hukum yang didirikan dan tunduk pada ketentuan hukum dan perundang-undangan
                        Negara Kesatuan Republik Indonesia.
                        </p>
                    </li>
                    <li>
                        <p>
                        Penyelenggara dalam menjalankan kegiatan usahanya tidak melanggar hak atas kekayaan intelektual dari
                        pihak ketiga lainnya.
                        </p>
                    </li>
                    <li>
                        <p>
                        Penyelenggara menjamin bahwa dana pemodal yang ada dalam rekening virtual account dan/atau escrow
                        account atas nama Penyelenggara dan/atau atas nama bersama dari Penyelenggara, Penerbit dan Pemodal
                        adalah merupakan dana milik masing-masing Pemodal, dan bukan merupakan harta kekayaan (aset) milik
                        Penyelenggara. Selanjutnya, harta (aset) tersebut tidak dapat dan bukan merupakan harta pailit (boedel
                        pailit), dalam hal Penyelenggara dinyatakan pailit berdasarkan peraturan perundang-undangan yang
                        berlaku.
                        </p>
                    </li>
                    </ul>
                    <h5> Privasi dan Keamanan </h5>
                    <p>
                    WinningHand menjamin keamanan data pribadi Penerbit dan Pemodal dalam menggunakan dan mengakses layanan urun
                    dana berbasis teknologi informasi pada Situs WinningHand. Semua informasi pribadi dari Penerbit maupun
                    Pemodal akan dilindungi sepenuhnya kerahasiannya.
                    </p>
                    <p>
                    WinningHand berhak untuk mengubah dan memperbarui syarat dan ketentuan penggunaan Situs WinningHand ini
                    sewaktu-waktu tanpa pemberitahuan sebelumnya kepada Penerbit dan/atau Pemodal. WinningHand akan
                    memberitahukan setiap perubahan-perubahan pada Situs WinningHand dan akan mengirimkan pemberitahuan melalui
                    email jika dirasa dan dipandang perlu. Perubahan dari syarat dan ketentuan akan berlaku efektik setelah
                    dimuat pada Situs WinningHand.
                    </p>
                    <h5> Pendaftaran Akun </h5>
                    <p>
                    Setiap orang dapat mendaftarkan diri menjadi anggota Situs WinningHand atau Pengguna dengan mengisi formulir
                    pendaftaran. Setelah pendaftaran disetujui dan memenuhi syarat dan ketentuan pembukaan akun, Pengguna dapat
                    mengunggah konten, mengajukan pendanaan usaha ataupun menjadi investor pada layanan urun dana yang
                    diselenggarakan oleh WinningHand.
                    </p>
                    <p>
                    Pengguna wajib memberikan alamat email aktif ketika mendaftar. Alamat email ini akan digunakan untuk
                    melakukan verifikasi identitas Pengguna pada kunjungan berikutnya. Apabila Pengguna mendaftar dengan
                    menggunakan alamat email fiktif atau alamat email milik orang lain, WinningHand dapat menutup akun Pengguna
                    tanpa pemberitahuan sebelumnya. Selain itu Penggguna wajib menyediakan password yang akan digunakan untuk
                    mengakses materi dan layanan Situs WinningHand.
                    </p>
                    <p>
                    Setelah Pengguna melengkapi data profil pada Situs WinningHand serta mengunggah dokumen persyaratan,
                    WinningHand akan melakukan verifikasi akun Pengguna dalam waktu 1x24 jam.
                    </p>
                    <p>
                    Pengguna tidak akan dikenakan biaya untuk membuka akun pada Situs WinningHand, Akan tetapi Pengguna akan
                    dikenakan biaya sesuai dengan tarif sebagaimana disebutkan dalam syarat dan ketentuan umum dan/atau
                    perjanjian antara Penyelenggara dengan Pengguna. Segala transaksi yang dilakukan harus melalui Saldo
                    WinningHand.
                    </p>
                    <p>
                    Jika masih terdapat pertanyaan mengenai layanan urun dana berbasis teknologi informasi yang diselenggarakan
                    oleh WinningHand, Anda dapat menyampaikan setiap pertanyaan Anda melalui <a
                        href="mailto:support@venturehub.id"> support@venturehub.id.</a>
                    </p>
                    <h5> Hak Kekayaan Intelektual </h5>
                    <p>
                    Seluruh desain, foto, logo dan gambar yang termuat pada Situs WinningHand adalah milik dari PT WinningHand dan
                    dilindungi hukum serta perundang-undangan Negara Kesatuan Republik Indonesia dan tidak diperkenankan untuk
                    menyebarluaskan desain, foto, logo dan gambar baik sebagian maupun secara keseluruhan oleh pihak manapun dan
                    untuk kepentingan apapun, tanpa persetujuan tertulis dari WinningHand.
                    </p>
                    <h5> Keadaan Kahar (force majeure) </h5>
                    <ul>
                    <li>
                        <p>
                        Penyelenggara dan/atau Pengguna dibebaskan dari kewajibannya berdasarkan Perjanjian ini dalam hal
                        terjadinya, dan selama terjadinya suatu Keadaan Kahar.
                        </p>
                    </li>
                    <li>
                        <p>
                        Yang dimaksud dengan Keadaan Kahar (“force majeure”) adalah suatu kejadian atau peristiwa yang terjadi
                        diluar daya upaya manusia dan/atau tidak dapat diduga atau diprediksi sebelumnya dan/atau diluar kendali
                        kekuasaan salah satu dari Penyelenggara dan/atau Pengguna untuk mengatasinya, yang mengakibatkan
                        terhambatnya pelaksanaan kewajiban salah satu dari Penyelenggara dan/atau Pengguna, termasuk namun tidak
                        terbatas pada:
                    </p>
                    <ul>
                        <li>
                        <p>
                            Kejadian atau peristiwa yang terjadi atas kehendak Tuhan, termasuk namun tidak terbatas pada bencana
                            alam angin topan, angin puting beliung, wabah penyakit, gempa bumi, petir, banjir, tsunami,
                            kebakaran, tanah longsor dan/atau bencana alam lainnya.
                        </p>
                        </li>
                        <li>
                        <p>
                            Huru-hara, kerusuhan sipil, peperangan, pemberontakan, mogok kerja, sabotase, perbuatan
                            perusakan/penghancuran (vandalisme), embargo, tindakan pemerintah, perubahan peraturan
                            perundang-undangan, gangguan sistem teknologi informasi dan/atau kegagalan sistem serta alih kelola
                            sistem teknologi informasi, baik yang disebabkan oleh penyadapan oleh pihak ketiga maupun bukan
                            dan/atau sebab-sebab serupa lainnya, yang terjadi di luar kekuasaan dan kemampuan Penyelenggara
                            dan/atau Pengguna yang menyebabkan Penyelenggara dan/atau Pengguna tidak dapat memenuhi kewajibannya
                            berdasarkan syarat dan ketentuan umum ini.
                        </p>
                        </li>
                    </ul>
                    </li>
                    <li>
                    <p>
                        Dalam hal terjadi force majeure, Pihak yang tertimpa dan/atau terdampak oleh force majeure, wajib
                        memberitahukan secara tertulis atau melalui surat elektronik (email) kepada Pihak lain yang tidak
                        tertimpa dan/atau terdampak oleh force majeure, selambat-lambatnya 3 (tiga) hari kalender sejak
                        terjadinya force majeure dimaksud;
                    </p>
                    </li>
                    <li>
                    <p>
                        Dalam hal Force Majeure berlanjut hingga dan/atau berlangsung lebih dari 30 (tiga puluh) hari kalender
                        berturut-turut dan/atau berdasarkan besarnya dampak Force Majeure terhadap pelaksanaan Perjanjian ini,
                        dengan berdasarkan itikad baik, Penyelenggara dan Pengguna dapat melakukan konsultasi dan/atau
                        musyawarah untuk memutuskan kelanjutan dan/atau keberlangsungan dari Perjanjian ini.
                    </p>
                    </li>
                    <li>
                    <p>
                        Tidak satupun dari Penyelenggara dan/atau Pengguna bertanggung jawab atas kerugian yang mungkin timbul
                        dan/atau diderita oleh pihak lainnya dan/atau pihak ketiga lainnya yang disebabkan oleh suatu kegagalan
                        atau keterlambatan pelaksanaan dari Perjanjian ini yang disebabkan oleh force majeure. Namun demikian,
                        Pihak yang tertimpa dan/atau terdampak oleh force majeure, akan melakukan upaya yang sewajarnya untuk
                        memenuhi kewajibannya dan/atau mengurangi kerugian bagi Penyelenggara dan/atau Pengguna atas terjadinya
                        force majeure;
                    </p>
                    </li>
                </ul>
                <h5> Kerahasiaan </h5>
                <p>
                    Penyelenggara dan Pengguna sepakat dan setuju untuk menjaga kerahasiaan dari setiap data dan/atau data-data,
                    catatan-catatan, ringkasan, perjanjian, kontrak, laporan maupun informasi, termasuk namun tidak terbatas
                    pada laporan keuangan dan/atau proposal dalam format dan bentuk apapun, yang diungkapkan oleh salah satu
                    dari Penyelenggara dan/atau Pengguna kepada pihak lainnya dan/atau yang diperoleh berdasarkan syarat dan
                    ketentuan umum ini dan/atau perjanjian ikutan lainnya (untuk selanjutnya disebut “Informasi Rahasia”);
                </p>
                <p>
                    Tidak satupun dari Pengguna berhak dan/atau berwenang untuk melakukan pengungkapan kepada pihak ketiga,
                    membuat pengumuman kepada khalayak umum dan/atau siaran pers yang berkaitan dengan subjek maupun objek dari
                    syarat dan ketentuan umum ini. Kecuali salah satu dari Penyelenggara dan/atau Pengguna diwajibkan oleh
                    perintah pengadilan, penetapan pengadilan, ketentuan hukum, peraturan perundang-undangan, peraturan dari
                    badan dan/atau instansi pemerintah yang berwenang, termasuk namun tidak terbatas pada Otoritas Jasa Keuangan
                    Republik Indonesia, untuk mengungkapkan Informasi Rahasia terkait dengan syarat dan ketentuan umum ini, maka
                    pihak yang terkena kewajiban tersebut wajib untuk melakukan pemberitahuan secara tertulis selambat-lambatnya
                    3 (tiga) Hari Kerja sebelumnya kepada pihak lainnya. Selanjutnya, ketika pengungkapan Informasi Rahasia akan
                    dilakukan oleh Pengguna, Pengguna wajib untuk meminta persetujuan tertulis terlebih dahulu dari
                    Penyelenggara sebelum pengungkapan dilakukan dan Penyelenggara tidak akan menunda dalam pemberian
                    persetujuan tertulisnya tanpa alasan yang wajar.
                </p>
                <p>
                    Kewajiban untuk menjaga kerahasiaan dari Informasi Rahasia dan/atau syarat dan ketentuan umum ini
                    sebagaimana diatur dalam dalam ketentuan ini akan tetap dan terus berlaku sekurang-kurangnya sampai dengan
                    tiga (3) tahun setelah Perjanjian ini berakhir.
                </p>
                <p>
                    Setiap saat, apabila diminta secara tertulis oleh pemilik Informasi Rahasia, seluruh Informasi Rahasia wajib
                    dikembalikan oleh pihak yang memegang dan/atau menguasai Informasi Rahasia dimaksud kepada pemilik Informasi
                    Rahasia. Akan tetapi, terhadap Informasi Rahasia yang tidak diminta untuk dikembalikan oleh pemilik
                    Informasi Rahasia, wajib dimusnahkan dalam waktu selambat-lambatnya 7 (tujuh) hari setelah pemutusan
                    dan/atau pengakhiran perjanjian antara Penyelenggara dan Pengguna, dan pemegang dan/atau pihak yang
                    menguasai Informasi Rahasia tersebut akan memberikan konfirmasi dan/atau pernyataan tertulis yang menyatakan
                    bahwa pemusnahan tersebut telah dilakukan.
                </p>
                <h5> Penyelesaian dan Hukum</h5>
                <p>
                    Syarat dan Ketentuan yang termuat pada Situs WinningHand diatur oleh dan diartikan secara keseluruhan sesuai
                    dengan Hukum Negara Kesatuan Republik Indonesia. Penyelenggara dan Pengguna dengan ini secara tegas
                    menyetujui untuk melaksanakan kewajiban mereka masing-masing sesuai dengan peraturan perundang-undang yang
                    berlaku saat ini dan/atau di kemudian hari.
                </p>
                <p>
                    Segala perbedaan, perselisihan dan/atau sengketa yang timbul dari pelaksanaan layanan urun dana maupun
                    Syarat dan Ketentuan ini akan diselesaikan secara musyawarah untuk mufakat, apabila penyelesaian sengketa
                    secara musyawarah untuk mufakat tidak tercapai dalam waktu selambat-lambatnya 30 (tiga puluh) hari kalender,
                    Penyelenggara dan Pengguna sepakat untuk menyelesaikan Sengketa yang timbul melalui Badan Arbitrase Nasional
                    Indonesia (“BANI”) berdasarkan dan sesuai dengan ketentuan dan peraturan hukum Republik Indonesia serta
                    aturan penyelesaian Sengketa yang berlaku pada BANI.
                </p> -->
                <div v-html="text">

                </div>
                <div class="form-group">
                    <div class="d-flex align-items-start mt-4">
                      <div class="d-flex checkbox-terms align-items-start">
                        <div class="clearfix" style="width: 30px;">
                          <input
                            class="styled-checkbox"
                            id="styled-checkbox-1"
                            type="checkbox"
                            value="value1"
                            v-model="checked"
                          />
                          <label style="top: 0;" class="mb-0" for="styled-checkbox-1"></label>
                        </div>
                        <p class="mb-0 font-14">
                            Saya telah membaca dan menyetujui
                          <span href="" class="text-blue">Syarat dan Ketentuan</span> dan
                          <span href="" class="text-blue">Kebijakan Privasi</span>
                        </p>
                      </div>
                    </div>
                  </div>
            </div>
            <div class="modal-footer">
                <button :disabled="isDisabled" type="button" class="btn btn-primary mr-2" data-dismiss="modal" @click="$emit('submit')">Daftar</button>
                <button style="font-size: 14px;" type="button" class="btn btn-secondary" data-dismiss="modal" @click="$emit('close')">Kembali</button>
            </div>
          </div>
        </div>
      </div>
</template>

<script>
import TermAndConditionService from '../../store/services/TermAndConditionService'
export default {
  name: 'TermsConditionModal',
  data () {
    return {
      text: '',
      checked: false
    }
  },
  mounted () {
    TermAndConditionService.getData().then(res => {
      console.log(res)
      this.text = res.data.data.termsCondition
    })
  },
  computed: {
    isDisabled () {
      return !this.checked
    }
  },
  watch: {
    checked () {
      console.log(this.checked)
    }
  }
}
</script>

<style>

</style>
