import { rawRequest } from '../http_common.js'

class ProjectService {
  getProjects (params) {
    return rawRequest.get('/project', { params })
  }

  getAllWhProjects (params) {
    return rawRequest.get('/project/wh-all', { params })
  }

  getProject (id) {
    return rawRequest.get(`/project/${id}`).then(res => {
      const data = res.data.data
      return data
    }).catch(e => {
      console.log(e)
      return Promise.reject(e)
    })
  }

  getMyProjects (id, params) {
    return rawRequest.get(`/project/user/${id}`, { params })
  }

  getGraphProject (id, params) {
    return rawRequest.get(`/project/get-graph/${id}`, { params })
  }

  getLaporanProject (id, params) {
    return rawRequest.get(`/project/laporan/${id}`, { params })
  }

  getProjectStatusDividend (params) {
    return rawRequest.get('/project/status/dividend', { params })
  }

  uploadCompanyDocs (body) {
    return rawRequest.post('/company-document/upload', body).then(res => {
      return res
    }).catch(e => {
      return Promise.reject(e)
    })
  }
}

export default new ProjectService()
