const dashboardTabs = {
  state: {
    dashboard: '',
    profile: ''
  },
  mutations: {
    setDashboard (state, payload) {
      state.dashboard = payload
    },
    setProfile (state, payload) {
      state.profile = payload
    },
    clearDashboard (state) {
      state.dashboard = ''
    },
    clearProfile (state) {
      state.profile = ''
    }
  }
}

export default dashboardTabs
