import TopupServices from '../services/TopupServices'

const topup = {
  state: {
    isLoading: false,
    topup: {},
    sendBukti: {}
  },
  mutations: {
    loading (state, status) {
      state.isLoading = status
    },
    topupSaldo (state, payload) {
      state.topup = payload
    },
    clearTopup (state) {
      state.topup = {}
    }
  },
  actions: {
    topupSaldo ({ commit }, { data }) {
      TopupServices.requestTopup(data).then(
        res => {
          commit('topupSaldo', res)
          commit('loading', false)
        },
        e => {
          console.log(e)
          commit('clearTopup')
          commit('loading', false)
        }
      )
    }
  }
}

export default topup
