<template>
  <div
    class="modal fade modal-medium topup-balance"
    id="topup-balance-02"
    tabindex="-1"
    role="dialog"
    style="overflow: auto"
    data-backdrop="static"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <a-spin :spinning="isLoading">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header d-block no-border">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="@/assets/img/XCircleOutline.png" />
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="text-center">
              <h5
                class="modal-title text-center mt-0 font-22 mb-0"
                id="exampleModalLabel"
              >
                Top Up Balance
              </h5>
              <p class="font-18 mb-0 text-gray">
                Following your top up details
              </p>

              <form class="mt-4 form-sign text-left">
                <!-- <div
                  class="alert alert-success mt-4 mb-5 d-flex align-items-start"
                  role="alert"
                >
                  <img
                    src="@/assets/img/CheckCircleOutline.png"
                    class="img-fluid mr-2"
                  />
                  <div class="clearfix">
                    <h4>Top Up Pending</h4>
                    <p class="mb-0">
                      Please complete your payment with the following information
                      before 15 May 2021. After paying the funds will be added to
                      your balance
                    </p>
                  </div>
                </div> -->
                <div
                  class="alert alert-warning mt-4 mb-5 d-flex align-items-start"
                  role="alert"
                >
                  <img
                    src="@/assets/img/InformationCircleOutline.png"
                    class="img-fluid"
                  />
                  <div class="clearfix">
                    <h4>Top Up Pending</h4>
                    <p class="mb-0">
                      Please transfer your top up amount to 0000xxxx
                    </p>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-12 payment-box">
                    <div class="clearfix mt-0">
                      <label>Payment Reference</label>
                      <div class="box p-3">
                        <div class="d-md-flex align-items-start">
                          <div class="clearfix mt-2 mb-2">
                            <h5><strong>Top Up Balance</strong></h5>
                          </div>
                          <div class="clearfix ml-auto mt-2 mb-2">
                            <h5>Amount</h5>
                            <h4 class="font-28 text-blue mb-0">
                              <strong>{{
                                topup
                                  ? topup.transaction.amount
                                  : "0" | currency
                              }}</strong>
                            </h4>
                          </div>
                        </div>

                        <hr />

                        <div class="d-md-flex align-items-center">
                          <div class="clearfix mt-2">
                            <h5><strong>Payment Code</strong></h5>
                            <small class="text-muted mb-2"
                              >Please copy this code and add to your bank
                              transfer note.</small
                            >
                            <h4 class="font-28 text-black mt-2">
                              <strong ref="copyCode">{{
                                topup ? topup.paymentCode : ""
                              }}</strong>
                            </h4>
                          </div>
                          <div class="clearfix ml-auto mt-2 mb-2">
                            <button
                              type="button"
                              v-clipboard="topup ? topup.paymentCode : ''"
                              class="
                                btn btn-default btn-outline-success
                                copy-code
                              "
                            >
                              <img
                                src="@/assets/img/DuplicateOutline.png"
                                class="mr-2"
                              />
                              Copy Code
                            </button>
                          </div>
                        </div>
                      </div>

                      <div class="box p-3">
                        <div class="d-md-flex align-items-start">
                          <div class="clearfix mt-2 mb-2">
                            <h5>Bank Code</h5>
                            <h5 class="font-18 mb-0">
                              <strong>{{
                                topup ? topup.paymentCode : ""
                              }}</strong>
                            </h5>
                          </div>
                          <div class="clearfix ml-auto mt-2 mb-2">
                            <h5>Expired Date</h5>
                            <h5 class="font-18 mb-0">
                              <strong>{{ topup ? topup.expire : "" }}</strong>
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div class="box p-3">
                        <div
                          class="
                            d-md-flex
                            align-items-center
                            justify-content-center
                            pt-2
                            pb-2
                            flex-column
                            text-center
                          "
                        >
                          <p class="text-gray">
                            Upload Proof of Payment to completing your payment
                            by bank transfer
                          </p>
                          <div class="upload-btn-wrapper">
                            <label for="upload_image" class="custom-file-upload"
                              >Upload here</label
                            >
                            <input
                              ref="bukti"
                              v-on:change="uploadBukti()"
                              id="upload_image"
                              type="file"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer no-border">
            <div class="form-inline mb-3">
              <button
                class="btn btn-primary ml-1 mr-1 mb-2"
                @click="requestTopup"
                :disabled="isEmpty"
                data-dismiss="modal"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import axios from 'axios'
import FormData from 'form-data'
import Vue from 'vue'
import VueClipboards from 'vue-clipboards'

Vue.use(VueClipboards)

export default {
  name: 'Topup2',
  props: ['topup'],
  data () {
    return {
      isLoading: false,
      isSuccess: false,
      isError: false,
      bukti: '',
      errorMessage: '',
      code: 'B12312CDX'
    }
  },
  computed: {
    isEmpty () {
      if (this.bukti) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    uploadBukti () {
      this.bukti = this.$refs.bukti.files[0]
    },
    requestTopup () {
      this.isLoading = true
      // const user = JSON.parse(localStorage.getItem('user'))
      // const token = user.token
      const token = localStorage.getItem('bearerToken')
      const formData = new FormData()
      formData.append('buktiTransfer', this.bukti)
      const instance = axios.create({
        baseURL: process.env.VUE_APP_BASE_URL,
        headers: { Authorization: token, 'Content-Type': 'multipart/form-data' }
      })
      instance.post(`/saldo/send-bukti/${this.topup.id}`, formData).then(res => {
        this.isLoading = false
        this.$emit('status', 'success')
      }).catch(e => {
        this.errorMessage = e.response.data.errorMessages[0].message
        this.isLoading = false
        this.$emit('status', 'error')
      })
    },
    updateModal () {
      this.$nextTick().then(() => document.body.classList.remove('modal-open'))
    }
  }
}
</script>

<style scoped>
.row {
  margin-left: 0;
  margin-right: 0;
}
.topup-balance h5 {
  margin-bottom: 0 !important;
}
</style>
