<template>
  <div
    class="modal fade modal-medium topup-balance"
    id="topup-balance-01"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    data-backdrop="static"
    aria-hidden="true"
    style="z-index: 2000"
  >
    <a-spin :spinning="isLoading">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header d-block no-border">
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <img src="@/assets/img/XCircleOutline.png" />
            </button>
          </div>
          <div class="modal-body pt-0">
            <div class="text-center">
              <h5
                class="modal-title text-center mt-0 font-22 mb-0"
                id="exampleModalLabel"
              >
                Isi Saldo
              </h5>
              <p class="font-18 mb-0 text-gray">Silahkan Isi Saldo Anda</p>

              <form autocomplete="off" class="mt-4 form-sign text-left">
                <div class="row">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label class="mb-2 font-weight-regular" for=""
                        >Jumlah yang akan ditambahkan:</label
                      >
                      <input
                        type="text"
                        class="form-control"
                        id=""
                        aria-describedby=""
                        v-model.trim="$v.topupAmount.$model"
                        placeholder="Rp.750.000"
                      />
                      <div v-if="$v.topupAmount.$error">
                        <div v-if="!$v.topupAmount.required">
                          <small id="passwordHelp" class="text-danger">
                            Harus diisi.
                          </small>
                        </div>
                        <div v-if="!$v.topupAmount.numeric">
                          <small id="passwordHelp" class="text-danger">
                            Harus menggunakan angka.
                          </small>
                        </div>
                      </div>
                      <div v-if="isLessThan">
                        <small id="passwordHelp" class="text-danger">
                          Minimum isi saldo adalah {{ minimum | currency }}
                        </small>
                      </div>
                      <p class="font-18 mt-2 mb-4 text-gray">
                        Jumlah yang harus anda bayar:
                        <span class="text-blue">{{
                          returnValue | currency
                        }}</span>
                      </p>
                    </div>
                  </div>

                  <div class="col-md-12 payment-box">
                    <div class="clearfix mt-0">
                      <label>Pilih Metode Pembayaran</label>
                      <div class="box row align-items-center m-0">
                        <p class="mb-4 col-md-12">
                          <strong>Bank Transfer</strong>
                        </p>
                        <ul class="input-group ml-auto col-md-12 flex-column">
                          <!-- <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-36"
                                  type="radio"
                                  name="payment"
                                  value="VIRTUAL_ACCOUNT_BANK_DANAMON"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-36"></label>
                              </div>
                              <p class="mb-0">Virtual Account Danamon</p>
                            </div>
                            <img
                              src="@/assets/img/danamon.png"
                              class="img-fluid ml-auto"
                            />
                          </li> -->
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input class="styled-radio"
                                id="reg-radio-10" type="radio"
                                name="payment"
                                value="BCA"
                                v-model="paymentMethod">
                                <label class="mb-0" for="reg-radio-10"></label>
                              </div>
                              <p class="mb-0">BCA Virtual Account</p>
                            </div>
                            <img src="@/assets/img/logo-bca.png" class="img-fluid ml-auto">
                          </li>
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-30"
                                  type="radio"
                                  name="payment"
                                  value="PERMATA"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-30"></label>
                              </div>
                              <p class="mb-0">Permata Virtual Account</p>
                            </div>
                            <img
                              src="@/assets/img/logo-permata.png"
                              class="img-fluid ml-auto"
                            />
                          </li>
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-31"
                                  type="radio"
                                  name="payment"
                                  value="BNI"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-31"></label>
                              </div>
                              <p class="mb-0">BNI Virtual Account</p>
                            </div>
                            <img
                              src="@/assets/img/logo-bni.png"
                              class="img-fluid ml-auto"
                            />
                          </li>
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-32"
                                  type="radio"
                                  name="payment"
                                  value="BRI"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-32"></label>
                              </div>
                              <p class="mb-0">BRI Virtual Account</p>
                            </div>
                            <img
                              src="@/assets/img/logo-bri.png"
                              class="img-fluid ml-auto"
                            />
                          </li>
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-33"
                                  type="radio"
                                  name="payment"
                                  value="MANDIRI"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-33"></label>
                              </div>
                              <p class="mb-0">Virtual Account Mandiri</p>
                            </div>
                            <img
                              src="@/assets/img/mandiri.png"
                              class="img-fluid ml-auto"
                            />
                          </li>
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-34"
                                  type="radio"
                                  name="payment"
                                  value="VIRTUAL_ACCOUNT_BANK_SYARIAH_MANDIRI"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-34"></label>
                              </div>
                              <p class="mb-0">Virtual Account Mandiri Syariah</p>
                            </div>
                            <img
                              src="@/assets/img/mandiri_syariah.png"
                              class="img-fluid ml-auto"
                            />
                          </li>
                          <!-- <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-35"
                                  type="radio"
                                  name="payment"
                                  value="VIRTUAL_ACCOUNT_CIMB"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-35"></label>
                              </div>
                              <p class="mb-0">Virtual Account CIMB</p>
                            </div>
                            <img
                              src="@/assets/img/cimb.png"
                              class="img-fluid ml-auto"
                            />
                          </li> -->
                        </ul>

                        <p class="mb-4 mt-4 col-md-12">
                          <strong>Pilihan Pembayaran Lain</strong>
                        </p>
                        <ul class="input-group ml-auto col-md-12 flex-column">
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-50"
                                  type="radio"
                                  name="payment"
                                  value="ALFAMART"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-50"></label>
                              </div>
                              <p class="mb-0">Alfamart</p>
                            </div>
                            <img
                              src="@/assets/img/alfamart.png"
                              class="img-fluid ml-auto"
                            />
                          </li>
                          <li class="d-flex align-items-center">
                            <div class="d-flex radio-terms align-items-center">
                              <div class="clearfix">
                                <input
                                  class="styled-radio"
                                  id="reg-radio-51"
                                  type="radio"
                                  name="payment"
                                  value="INDOMARET"
                                  v-model="paymentMethod"
                                />
                                <label class="mb-0" for="reg-radio-51"></label>
                              </div>
                              <p class="mb-0">Indomaret</p>
                            </div>
                            <img
                              src="@/assets/img/logo-indomaret.png"
                              class="img-fluid ml-auto"
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer no-border">
            <div class="form-inline mb-3">
              <button
                class="btn btn-outline-success ml-1 mr-1 mb-2"
                type="button"
                data-dismiss="modal"
              >
                Cancel
              </button>
              <button
                class="btn btn-primary ml-1 mr-1 mb-2"
                type="button"
                :disabled="isDisable"
                data-dismiss="modal"
                @click="nextModal()"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators'
import SettingService from '../../store/services/SettingService'
import TopupServices from '../../store/services/TopupServices'
// import BiayaLayananService from '../../store/services/BiayaLayananService'

export default {
  name: 'ModalTopup',
  data () {
    return {
      isLoading: false,
      topupAmount: '',
      modal2: '',
      nameModal2: '',
      total: 0,
      minimum: 0,
      paymentMethod: '',
      fee: 0
    }
  },
  validations: {
    topupAmount: {
      required,
      numeric
    }
  },
  computed: {
    returnValue () {
      return parseInt(this.topupAmount) + this.fee
    },
    isDisable () {
      if (this.$v.$invalid || this.isLessThan || !this.paymentMethod) {
        return true
      } else {
        return false
      }
    },
    isLessThan () {
      if (this.minimum > this.topupAmount) {
        return true
      } else {
        return false
      }
    }
  },
  mounted () {
    const user = JSON.parse(localStorage.getItem('user'))
    if (user) {
      SettingService.getSetting().then(res => {
        this.minimum = res.minimalTopup
        // const jokulScript = document.createElement('script')
        // jokulScript.setAttribute('src', 'https://sandbox.doku.com/jokul-checkout-js/v1/jokul-checkout-1.0.0.js')
        // document.head.appendChild(jokulScript)
      })
      /*
      BiayaLayananService.getFee().then((res) => {
        this.fee = res.feeTopup
      })
      */
    }
  },
  methods: {
    nextModal () {
      this.isLoading = true
      const user = JSON.parse(localStorage.getItem('user'))
      const body = {
        idUser: user.id,
        amount: this.topupAmount,
        payment: this.paymentMethod
      }
      TopupServices.requestTopupMidtrans(body).then(res => {
        window.location.href = res.midtransToken
      }).catch(e => {
        this.$parent.errorMessage = e.response.data.errorMessages[0].message
        this.$parent.openErrorModal()
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>
.topup-balance .form-control {
    font-weight: bold;
    font-size: 28px;
    line-height: 34px;
    letter-spacing: -0.4px;
    color: #222531;
    font-family: "Inter", sans-serif;
    height: 66px;
    border: 1px solid #e0e0e0;
}
.row {
  margin-left: 0;
  margin-right: 0;
}
</style>
