import TransactionServices from '../services/TransactionServices'

const transaction = {
  state: {
    sendBukti: {},
    isLoading: false,
    detailTransaction: {},
    topup: {}
  },
  mutations: {
    loading (state, payload) {
      state.isLoading = payload
    },
    getTransaction (state, payload) {
      state.detailTransaction = payload
    },
    clearTransaction (state) {
      state.detailTransaction = {}
    },
    sendBuktiMethod (state, payload) {
      state.sendBukti = payload
    },
    clearBukti (state) {
      state.sendBukti = {}
    },
    getTopup (state, payload) {
      state.topup = payload
    },
    clearTopup (state) {
      state.topup = {}
    }
  },
  actions: {
    sendBukti ({ commit }, { id, body }) {
      commit('loading', true)
      TransactionServices.sendBukti(id, body).then(
        res => {
          commit('sendBuktiMethod', res.data.data)
          commit('clearError')
          commit('loading', false)
        },
        e => {
          commit('changeLoading', false)
          commit('onErrorRequest', e.response)
          commit('clearBukti')
        }
      )
    },
    getTransaction ({ commit }, { id }) {
      commit('loading', true)
      TransactionServices.getById(id).then(
        res => {
          commit('getTransaction', res.data.data)
          commit('clearError')
          commit('loading', false)
        },
        e => {
          commit('clearTransaction')
          commit('changeLoading', false)
          commit('onErrorRequest', e.response)
        }
      )
    },
    getTopup ({ commit }, { id }) {
      commit('loading', true)
      TransactionServices.getTopup(id).then(
        res => {
          commit('getTopup', res.data.data)
          commit('clearError')
          commit('loading', false)
        },
        e => {
          commit('clearTopup')
          commit('changeLoading', false)
          commit('onErrorRequest', e.response)
        }
      )
    },
    clearTopup ({ commit }) {
      commit('clearTopup')
    }
  }
}

export default transaction
