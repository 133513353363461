import axios from 'axios'
import { authHeader, multipartHeader } from './http_headers.js'
const url = process.env.VUE_APP_BASE_URL

export const multipartRequest = axios.create({
  baseURL: url,
  headers: multipartHeader()
})

export const rawRequest = axios.create({
  baseURL: url,
  headers: authHeader()
})

export const base64Request = axios.create({
  baseURL: url,
  headers: authHeader()
})
