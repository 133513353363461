<template>
  <div id="wrapper">
    <section>
      <div class="container-fluid p-0">
        <div class="row box-sign m-0">
          <div class="col-md-12 pt-5 pb-4">
            <router-link to="/" class="logo"
              ><img src="@/assets/img/logo.png"
            /></router-link>
          </div>
          <div class="col-md-7">
            <h2 class="title">Business Accelerator</h2>
            <ul class="mt-5 mb-5 funding-list list-unstyled">
              <li class="d-flex align-items-start">
                <div class="left-icon">
                  <img src="@/assets/img/ic-checklist.png" class="img-fluid" />
                </div>
                <div class="right-text">
                  <h4>Network</h4>
                  <p>
                    Kami dapat menghubungkan Anda dengan customer, supplier, atau mitra, yang dapat memberikan nilai tambah bagi bisnis Anda.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start">
                <div class="left-icon">
                  <img src="@/assets/img/ic-checklist.png" class="img-fluid" />
                </div>
                <div class="right-text">
                  <h4>Funding</h4>
                  <p>
                    Ketika bisnis Anda memerlukan pendanaan, baik itu equity maupun loan, kami dapat membantu Anda menghubungkan dengan investor.
                  </p>
                </div>
              </li>
              <li class="d-flex align-items-start">
                <div class="left-icon">
                  <img src="@/assets/img/ic-checklist.png" class="img-fluid" />
                </div>
                <div class="right-text">
                  <h4>Growth</h4>
                  <p>
                    Pada saat Anda siap untuk ke langkah besar, seperti IPO atau akuisisi, kami bantu Anda mencapai kesuksesan yang Anda inginkan.
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <router-view />
        </div>
      </div>
    </section>
    <Footer1 />
  </div>
</template>

<script>
import Footer1 from '../components/Layout/Footer1.vue'

export default {
  name: 'Auth',
  components: {
    Footer1
  },
  beforeCreate () {
  },
  methods: {
    toHome () {
      this.$router.push({ name: 'Home' })
    }
  }
}
</script>

<style scoped>
@import '../assets/css/signin.css';
</style>
