import ProjectService from '../services/ProjectService'

const project = {
  state: {
    isLoading: false,
    detailProject: {},
    projectId: ''
  },
  mutations: {
    loading (state, status) {
      state.isLoading = status
    },
    getProject (state, payload) {
      state.detailProject = payload
    },
    changeProjectId (state, payload) {
      state.projectId = payload
    }
  },
  actions: {
    getProject ({ commit }, { id }) {
      ProjectService.getProject(id)
        .then(
          res => {
            commit('getProject', res)
            commit('loading', false)
          },
          error => {
            console.log(error)
            commit('clearUser')
            commit('loading', false)
          }
        )
    },
    getDetailProject ({ commit }, { id }) {
      ProjectService.getProject(id)
        .then(
          res => {
            commit('getProject', res)
            commit('loading', false)
          },
          error => {
            console.log(error)
            commit('clearUser')
            commit('loading', false)
          }
        )
    }
  }
}

export default project
