<template>
  <div class="mt-5 who-work">
    <div>&nbsp;</div>
    <h2 class="title text-center">Who should <span class="tblue">work with</span> us</h2>
    <div class="row">
      <div class="col-md-7 text-right">
        <img src="@/assets/img/wh-office.png" class="img-fluid mb-3" />
      </div>
      <div class="col-md-5 who-work-with">
        <div style="height: 90px;">&nbsp;</div>
        <div>
          <h3 class="subtitle mb-2">Established companies</h3>
          <div class="text-gray mb-4">
            <div>Who are under debt pressure; or</div>
            <div>Who is looking for investors; or</div>
            <div>Who is considering IPO for legacy</div>
          </div>
        </div>
        <div>
          <h3 class="subtitle mb-2">Small Medium Businesses</h3>
          <div class="text-gray mb-4">
            <div>Who needs working capital; or</div>
            <div>Who is keen to explore M&A</div>
          </div>
        </div>
        <div>
          <h3 class="subtitle mb-2">Startups</h3>
          <div class="text-gray mb-4">
            <div>Who needs working capital; or</div>
            <div>Who is struggling to grow</div>
            <div>Whose investors are pressing for exit</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Col, Divider, Row } from 'ant-design-vue'

Vue.use(Col)
Vue.use(Divider)
Vue.use(Row)

export default {
  name: 'Section2A'
}
</script>

<style scoped lang="css">
.who-work {
  width: 98%;
}
.blue-line{
  border-bottom: 2px solid #1FBEE8;
  margin: auto;
  width: 200px;
  margin-bottom: 20px;
}
.who-work-with {
  text-align: center;
}
@media (min-width: 768px ) {
  .who-work-with {
    text-align: left;
  }
}
</style>
