<template>
  <div class="modal-success">
    <div class="success-header">
      <div class="icon"><a-icon type="check-circle" /></div>
    </div>
    <div class="success-body">
      <h5 class="modal-title mt-2 mb-3"><b>Berhasil</b></h5>
      <p class="mb-4 modal-message">{{ message }}</p>
      <button class="btn mb-3" @click="$emit('close')"><b>{{ text }}</b></button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalSuccess',
  props: ['message', 'buttonText'],
  computed: {
    text () {
      if (this.buttonText) {
        return this.buttonText
      } else {
        return 'Tutup'
      }
    }
  }
}
</script>

<style scoped>
.modal-message {
  font-size: 14px;
}
.modal-success {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.616);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;
  justify-content: center;
}

.success-header {
  width: 400px;
  background-color: #1fbee8;
  display: flex;
  justify-content: center;
  height: 80px;
  color: white;
}
.icon {
  display: flex;
  font-size: 70px;
  animation: myrotate 2s;
  align-items: center;
}

.success-body {
  width: 400px;
  background-color: white;
  text-align: center;
}

.btn {
  background-color: #1fbee8;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  color: white;
}
.btn:hover {
  color: #00d8ff;
}

@keyframes myrotate {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
