import OnboardingDanamonService from '../services/OnboardingDanamonService'

const onboardingDanamon = {
  state: {
    isLoading: false,
    resOnboarding: {},
    resInquiryStatus: {}
  },
  mutations: {
    setResOnboarding (state, payload) {
      state.resOnboarding = payload
    },
    setInquiryStatus (state, payload) {
      state.inquiryStatus = payload
    },
    clearInquiryStatus (state) {
      state.inquiryStatus = {}
    },
    clearOnboarding (state) {
      state.resOnboarding = null
    },
    loading (state, payload) {
      state.isLoading = payload
    }
  },
  actions: {
    onboardingDataGathering ({ commit }, { id }) {
      commit('loading', true)
      OnboardingDanamonService.onBoarding(id)
        .then(
          res => {
            console.log('data gathering response ', res)
            commit('setResOnboarding', res)
          },
          error => {
            console.log('data gathering catch ', error.response)
            commit('setResOnboarding', error.response)
            commit('onErrorRequest', error.response)
            // commit('clearOnboarding')
          }
        ).finally(() => commit('loading', false))
    },

    inquiryStatus ({ commit }, { id, body }) {
      commit('loading', true)
      OnboardingDanamonService.inquiryStatusOnBoarding(id, body)
        .then(
          res => {
            commit('setInquiryStatus', res)
          },
          error => {
            commit('onErrorRequest', error.response)
            commit('clearInquiryStatus')
          }
        ).finally(() => commit('loading', false))
    }
  }
}

export default onboardingDanamon
