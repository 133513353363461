<template>
  <div id="wrapper">
    <Header1 ref="header" isDash="true"/>
    <a-spin size="large" :spinning="isLoading">
    <section style="margin-top: 86px">
      <div class="container-fluid" style="margintop: 71px">
        <div class="dashboard pt-5 pb-5" style="display: flex;
        max-width: 100%;">
          <div class="dash-menu d-flex flex-column">
            <div class="profile">
              <div class="photo">
                <div class="custom-upload">
                  <div class="custom-file-upload"
                      @click="$refs.profilePhoto.click()">
                    <img
                      v-if="user.photoProfile"
                      id="propic"
                      :src="user.photoProfile"
                      class="img-fluid"
                    />
                    <img
                      v-else
                      id="propic"
                      src="@/assets/img/avatar.png"
                      class="img-fluid"
                    />
                  </div>
                  <div class="custom-file-trigger">
                    <input
                      ref="profilePhoto"
                      @change="onFileSelect"
                      type="file"
                      accept=".jpg,.png,.jpeg"
                      id="imgInp-propic"
                    />
                    <img
                      id="btnChangeProfileImage"
                      src="@/assets/img/ic-camera.png"
                      class="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <h4 class="mb-2 text-white">
                {{ user.namaDepan + " " + user.namaBelakang }}
              </h4>
              <p class="mb-2 text-white">{{ user.email }}</p>
              <p class="mb-0 text-white">{{ mobilePhone }}</p>
            </div>
            <ul class="list-unstyled">
              <li>
                <a v-bind:class="{ active: position === 'Dashboard' }"
                @click="changeTabs('Dashboard')"
                  ><span class="mr-2"
                    ><img src="@/assets/img/ic-dashboard.png" /></span
                  >Dashboard</a
                >
              </li>
              <li>
                <a v-bind:class="{ active: position === 'MyProfile' }"
                @click="changeTabs('MyProfile')"
                ><span class="mr-2">
                  <img src="@/assets/img/ic-assignment.png" /></span>
                  Profile Saya
                </a>
              </li>
              <li>
                <a v-if="isPenerbit" v-bind:class="{ active: position === 'WhBusiness' }"
                v-on:click="changeTabs('WhBusiness')"
                  ><span class="mr-2"
                    ><img src="@/assets/img/ic-business.png" /></span
                  >Bisnis Saya</a
                >
              </li>
              <li>
                <a v-if="isPenerbit" v-bind:class="{ active: position === 'WhAssets' }"
                v-on:click="changeTabs('WhAssets')"
                  ><span class="mr-2"
                    ><img src="@/assets/img/ic-investment.png" /></span
                  >Asset Saya</a
                >
              </li>
              <li v-if="!isPenerbit">
                <a v-bind:class="{ active: position === 'TransactionHistory' }" v-on:click="changeTabs('TransactionHistory')"
                  ><span class="mr-2"
                    ><img src="@/assets/img/ic-transaction.png" /></span
                  >Investasi Saya</a
                >
              </li>
              <li v-if="!isPenerbit">
                <a v-bind:class="{ active: position === 'Shares' }" v-on:click="changeTabs('Shares')">
                  <span class="mr-2">
                    <img src="@/assets/img/ic-stock.png" /> </span
                  >Saham Saya
                </a>
              </li>
              <li v-if="!isPenerbit">
                <a v-bind:class="{ active: position === 'HistorySecondaryMarket' }" v-on:click="changeTabs('HistorySecondaryMarket')">
                  <span class="mr-2">
                    <img src="@/assets/img/ic-stock.png" /> </span
                  >Pasar Sekunder
                </a>
              </li>
              <li>
                <a v-bind:class="{ active: position === 'Settings' }" v-on:click="changeTabs('Settings')"
                  ><span class="mr-2"
                    ><img src="@/assets/img/ic-setting.png" /></span
                  >Pengaturan</a
                >
              </li>
            </ul>
            <div class="sign-out">
              <a @click="signOut"
                ><span class="mr-2"
                  ><img src="@/assets/img/ic-sign-out.png" /></span
                >Keluar</a
              >
            </div>
          </div>
          <div class="dash-content">
            <router-view />
          </div>
        </div>
      </div>
    </section>
    </a-spin>
    <Footer />
    <div
      class="modal fade"
      id="upload-image"
      tabindex="-1"
      role="dialog"
      aria-labelledby="kycAlertLabel"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <section class="cropper-area">
              <div class="img-cropper">
                <section class="preview-area">
                  <!-- <p>Preview</p>
                <div class="preview" /> -->
                  <!-- <p>Preview</p> -->
                  <div class="cropped-image d-flex justify-content-center">
                    <img
                      v-if="selectedFile"
                      :src="selectedFile"
                      alt="Cropped Image"
                      width="440px"
                    />
                    <div v-else class="crop-placeholder" />
                  </div>
                </section>
              </div>
            </section>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              style="margin-right: 10px"
              v-on:click="closeCrop"
            >
              Tutup
            </button>
            <button v-on:click="submitImage" class="btn btn-primary">
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div
      class="modal fade"
      id="kyc-alert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="kycAlertLabel"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Silahkan Lengkapi KYC Anda</h5>
          </div>
          <div class="modal-body">
            <img src="@/assets/image/information.png" class="img-center" />
            <p class="modal-message">
              Silahkan Lengkapi KYC terlebih dahulu untuk mengakses semua fitur.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              style="margin-right: 10px"
              data-dismiss="modal"
              v-on:click="closeModal"
            >
              Lewati
            </button>
            <button
              v-on:click="toKyc"
              data-dismiss="modal"
              class="btn btn-primary"
            >
              Berikutnya
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <!-- <div
      class="modal fade"
      id="waiting-alert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="waitingAlertLabel"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <img src="@/assets/image/information.png" class="img-center" />
            <p class="modal-message">Harap lengkapi profil Anda terlebih dahulu untuk membuka semua akses.</p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              style="margin-right: 10px"
            >
              Tutup
            </button>
          </div>
        </div>
      </div>
    </div> -->
    <div
      class="modal fade"
      id="reject-alert"
      tabindex="-1"
      role="dialog"
      aria-labelledby="waitingAlertLabel"
      data-toggle="modal"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-body">
            <img src="@/assets/image/information.png" class="img-center" />
            <p class="modal-message">
              KYC anda telah ditolak, tolong untuk mengisi data KYC lagi.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-secondary"
              data-dismiss="modal"
              style="margin-right: 10px"
            >
              Tutup
            </button>
            <button
              v-on:click="toKyc"
              data-dismiss="modal"
              class="btn btn-primary"
            >
              Berikutnya
            </button>
          </div>
        </div>
      </div>
    </div>
    <modal-warning
      :message="'Silahkan isi KYC terlebih dahulu untuk mengakses semua fitur.'"
      :button="true"
      :modalId="'kyc-alert'"
      v-on:clickNext="toKyc"
    />
    <modal-warning
      :message="'Harap lengkapi profil Anda terlebih dahulu untuk membuka semua akses.'"
      :button="false"
      :modalId="'waiting-alert'"
      v-on:clickNext="window.$('#waiting-alert').modal('hide')"
    />
    <modal-topup @showModal2="openModal2" />
    <modal-topup-2 :topup="dataForModal" @status="chekSuccess" />
    <modal-success
      v-if="isSuccess"
      @close="closeSuccess"
      :message="successMessage"
    />
    <modal-error
      v-if="isError"
      @close="isError = false"
      :message="errorMessage"
    />
  </div>
</template>

<script>
import Footer from '../components/Layout/Footer1'
import Header1 from '../components/Layout/Header1'
import ModalSuccess from '../components/alert/ModalSuccess.vue'
import ModalError from '../components/alert/ModalError.vue'
import { kycService } from '../store/services/kyc.services'
import ModalTopup from '../components/Payment/ModalTopup.vue'
import ModalTopup2 from '../components/Payment/ModalTopup2.vue'
import ModalWarning from '../components/alert/ModalWarning.vue'
import { Spin } from 'ant-design-vue'
import Vue from 'vue'

Vue.use(Spin)

export default {
  components: {
    Footer,
    Header1,
    ModalSuccess,
    ModalError,
    ModalTopup,
    ModalTopup2,
    ModalWarning
  },
  name: 'Dash',
  data () {
    return {
      isLoading: false,
      dataForModal: '',
      isSuccess: false,
      isError: false,
      nav1: true,
      nav2: false,
      nav3: false,
      nav4: false,
      nav5: false,
      nav6: false,
      nav7: false,
      nav8: false,
      nav9: false,
      position: '',
      mime_type: '',
      // photoProfile: '',
      cropedImage: '',
      autoCrop: false,
      selectedFile: '',
      image: '',
      successMessage: 'Success',
      files: '',
      errorMessage: '',
      // user: '',
      name: '',
      mobilePhone: ''
    }
  },
  mounted () {
    this.getUser()
    this.position = this.$store.state.dashboardTabs.dashboard
    console.log('this.$store.state.dashboardTabs', this.$store.state.dashboardTabs)
  },
  watch: {
    photoProfile (newValue, oldValue) {
      console.log('pp changed')
    },
    user () {
      console.log('User Object Changed')
      this.mobilePhone = this.user.mobile ? '+62' + this.user.mobile : ''
    },
    tabs () {
      this.position = this.tabs
      this.$router.push({ name: this.tabs })
      console.log('tab changes', this.tabs)
    }
  },
  computed: {
    photoProfile () {
      return this.$store.state.kyc.detailUser.photoProfile
    },
    user () {
      return this.$store.state.kyc.detailUser
    },
    isPenerbit () {
      if (this.user.tipeAkun === 'PENERBIT') {
        return true
      } return false
    },
    tabs () {
      return this.$store.state.dashboardTabs.dashboard
    }
  },
  methods: {
    chekSuccess (value) {
      if (value === 'success') {
        this.isSuccess = true
      } else {
        this.isError = true
      }
    },
    openModal2 (value) {
      window.$('#topup-balance-01').modal('hide')
      this.dataForModal = value
      window.$('#topup-balance-02').modal('show')
    },
    getUser () {
      this.$store.dispatch('getUser')
      // this.user = this.$store.state.kyc.detailUser
      // this.photoProfile = this.$store.state.kyc.detailUser.photoProfile
      // kycService.getUser().then((res) => {
      //   console.log(res)
      //   this.user = res
      //   this.photoProfile = res.photoProfile
      //   if (this.user.kycStatus === 'EMPTY') {
      //     window.$('#kyc-alert').modal('show')
      //   }
      // })
    },
    onFileSelect () {
      var file = this.$refs.profilePhoto.files[0]
      if (this.checkImageFile(file.type)) {
        // const file = e.target.files[0]
        if (file.size < 10048 * 10048) {
          this.files = file
          this.mime_type = file.type
          if (typeof FileReader === 'function') {
            window.$('#upload-image').modal('show')
            const reader = new FileReader()
            reader.onload = (event) => {
              this.selectedFile = event.target.result
              this.$refs.cropper.replace(event.target.result)
            }
            reader.readAsDataURL(file)
          } else {
            alert('Sorry, FileReader API not supported')
          }
        } else {
          alert('Gambar tidak boleh lebih dari 2MB')
        }
      }
    },
    checkImageFile (item) {
      console.log(item)
      if (item === 'image/jpeg' ||
          item === 'image/jpg' ||
          item === 'image/png') {
        return true
      } else {
        this.openErrorModal()
        this.errorMessage = 'Mohon Masukan File Image (JPG,JPEG,PNG)'
      }
    },
    closeCrop () {
      window.$('#upload-image').modal('hide')
    },
    submitImage  () {
      // this.cropedImage = this.$refs.cropper.getCroppedCanvas().toDataURL()
      // this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
      // }, this.mime_type)
      const formData = new FormData()
      formData.append('fotoProfile', this.files)
      this.closeCrop()
      this.isLoading = true
      kycService.uploadPp(formData).then(() => {
        window.$('#upload-image').modal('hide')
        this.successMessage = 'Foto profile Anda telah diperbaharui'
        this.$store.dispatch('getUser').then(() => {
          this.openSuccessModal()
        })
      }).catch(e => {
        this.closeCrop()
        console.log('error image upload 2', e.response)
        this.errorMessage = e.response.data.error
        this.openErrorModal()
      }).finally(() => {
        this.isLoading = false
      })
    },
    toKyc () {
      this.$router.push({ name: 'Kyc1' })
    },
    closeModal () {
      window.$('#kyc-alert').modal('hide')
    },
    changeTabs (value) {
      this.$store.commit('setDashboard', value)
    },
    dashboardClick () {
      this.changeActive(
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      )
      this.$router.push({ name: 'Dashboard' })
    },
    profileClick () {
      this.changeActive(
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      )
      this.$router.push({ name: 'MyProfile' })
    },
    businessClick () {
      this.changeActive(
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false,
        false
      )
      this.$router.push({ name: 'Business' })
    },
    investmentClick () {
      this.changeActive(
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false,
        false
      )
      this.$router.push({ name: 'TransactionHistory' })
    },
    walletClick () {
      this.changeActive(
        false,
        false,
        false,
        false,
        true,
        false,
        false,
        false,
        false
      )
      this.$router.push({ name: 'Wallet' })
    },
    sharesClick () {
      this.changeActive(
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false,
        false
      )
      this.$router.push({ name: 'Shares' })
    },
    historyClick () {
      this.changeActive(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true,
        false
      )
      this.$router.push({ name: 'HistorySecondaryMarket' })
    },
    settingsClick () {
      this.changeActive(
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        true
      )
      this.$router.push({ name: 'Settings' })
    },
    changeActive (val1, val2, val3, val4, val5, val6, val7, val8, val9) {
      this.nav1 = val1
      this.nav2 = val2
      this.nav3 = val3
      this.nav4 = val4
      this.nav5 = val5
      this.nav6 = val6
      this.nav7 = val7
      this.nav8 = val8
      this.nav9 = val9
    },
    closeSuccess () {
      this.isSuccess = false
      this.successMessage = 'Success'
    },
    signOut () {
      this.$cookies.keys().forEach((cookie) => this.$cookies.remove(cookie))
      this.$store
        .dispatch('logout')
        .then(() => this.$router.push({ name: 'Home' }))
    },
    openSuccessModal () {
      this.isSuccess = true
    },
    openErrorModal () {
      this.isError = true
    }
  },
  beforeRouteLeave (to, from, next) {
    next(vm => {
      vm.$store.commit('setDashboard', 'Dashboard')
    })
  }
}
</script>

<style scoped>

.custom-file-trigger:hover {
  cursor: pointer !important;
}

.img-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
}

.modal-message {
  font-size: 18px;
  margin: auto;
  font-style: bold;
  margin-top: 20px;
  color: black;
  text-align: center;
}

.sign-out a {
  margin-top: 30px;
  color: white;
  padding-right: 0;
  padding-left: 15px;
}

.sign-out a:hover {
  color: red;
  background: rgba(91, 127, 145, 0.51);
  border-radius: 30px 0 0 30px;
}

header {
  background: white;
}

ul.list-unstyled {
  border-bottom: 1px solid rgba(244,244,244,0.4);
  padding-bottom: 15px;
}
</style>
