<template>
  <header
    :class="{ fixed: scrollPosition > 40 }"
    :style="`border-bottom: ${
      scrollPosition > 40 ? '1px solid rgb(224, 224, 224);' : ''
    }
    background-color: ${scrollPosition > 73 ? 'white' : ''}`"
  >
    <div class="container-fluid">
      <nav class="navbar navbar-expand-lg navbar-light bg-white">
        <router-link class="navbar-brand" to="/"
          ><img
            src="@/assets/img/wh-black.svg"
            :style="`opacity: ${isDash ? '1' : ''} !important`"
        /></router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
          </ul>
          <ul
            class="list-unstyled d-flex align-items-center after-login"
          >
            <li>
              <div class="dropdown">
                <button
                  class="
                    btn btn-secondary
                    dropdown-toggle
                    d-flex
                    align-items-center
                  "
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span v-if="user.photoProfile" class="avatar"
                    ><img :src="user.photoProfile" class="img-fluid"
                  /></span>
                  <span v-else class="avatar"
                    ><img src="@/assets/img/avatar.png" class="img-fluid"
                  /></span>
                  <p
                    class="mb-0"
                    :style="`color: ${
                      isHome
                        ? scrollPosition < 73
                          ? 'white'
                          : ''
                        : isDash
                        ? 'black'
                        : ''
                    }`"
                  >
                    {{ user.namaDepan }} {{ user.namaBelakang }}
                  </p>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-right p-3"
                  aria-labelledby="dropdownMenuButton"
                >
                  <a class="dropdown-item" @click="onClickItemMenu"
                    >Dashboard</a
                  >
                  <a class="dropdown-item" href="/" @click="signOut">Keluar</a>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </header>
</template>

<script>
// import Dropdown from 'bp-vuejs-dropdown'
import Vue from 'vue'
import { Dropdown, Menu } from 'ant-design-vue'
// import StartFundService from '../../store/services/StartFundService'
// import { kycService } from '../../store/services/kyc.services'

Vue.use(Dropdown)
Vue.use(Menu)

export default {
  name: 'Header1',
  props: ['isHome', 'isDash'],
  data () {
    return {
      isUserDebit: this.$store.state.userDebit.isUserDebit,
      scrollPosition: null,
      // user: {},
      dropdownItem: [
        {
          index: 1,
          name: 'Dashboard'
        },
        {
          index: 2,
          name: 'Sign Out'
        }
      ],
      token: ''
    }
  },
  computed: {
    user () {
      return this.$store.state.kyc.detailUser
    },
    userDebit () {
      return this.$store.state.userDebit.resUserDebit
    },
    isPenerbit () {
      if (this.user.tipeAkun === 'PENERBIT') {
        return true
      } return false
    }
  },
  watch: {
    user () {
      return this.$store.state.kyc.detailUser
    },
    userDebit () {
      return this.$store.state.userDebit.resUserDebit
    }
  },
  methods: {
    // openModal (value) {
    //   if (this.user.statusDataDiri &&
    //     this.user.statusPekerjaan &&
    //     this.user.statusBank &&
    //     this.user.statusPreferensi &&
    //     this.user.statusDokumen) {
    //     window.$('#topup-balance-01').modal('show')
    //   } else {
    //     // if (this.)
    //     if (this.user.kycStatus === 'EMPTY') {
    //       window.$('#kyc-alert').modal('show')
    //     } else {
    //       window.$('#waiting-alert').show('show')
    //     }
    //   }
    // },
    startupCreate () {
      // console.log('user >>', this.user)
      if (this.user.kycStatus === 'APPROVED') {
        this.$router.push({ name: 'OnlineSubmitWh' })
        /*
        this.$store.dispatch('checkDraft')
        StartFundService.getDraftCount().then(res => {
          if (res.data.data.draft) {
            this.$router.push({ name: 'OnlineSubmit' })
          } else {
            this.$router.push({ name: 'StartYourRaises' })
          }
        })
        */
        // if (this.$store.getters.getHaveDraft) {
        //   this.$router.push({ name: 'OnlineSubmit' })
        // } else {
        // }
      } else {
        if (this.user.kycStatus === 'EMPTY') {
          window.$('#kyc-alert').modal('show')
        } else {
          window.$('#waiting-alert').modal('show')
        }
      }
    },
    checkKycStatus () {
      if (this.user.statusDataDiri &&
        this.user.statusPekerjaan &&
        this.user.statusBank &&
        this.user.statusPreferensi &&
        this.user.statusDokumen) {
        return true
      } else {
        return false
      }
    },
    updateScroll () {
      this.scrollPosition = window.scrollY
    },
    toWallet () {
      this.$router.push({ path: '/dash/dashboard', query: { ...this.$route.query, position: 'Transaction' } })
    },
    toStartYourRise () {
      this.$router.push({ name: 'StartYourRaises' })
    },
    onClickItemMenu () {
      // if (e === 1) {
      this.$store.commit('setDashboard', 'Dashboard')
      this.$store.commit('setProfile', 'PI')
      this.$router.push({ name: 'Dashboard' })
      // }
    },
    toListStartup () {
      this.$router.push({ name: 'ListStartup' })
    },
    signOut () {
      this.$cookies.keys().forEach(cookie => this.$cookies.remove(cookie))
      this.$store.dispatch('logout').then(() => {
        this.$router.push({ name: 'Home' })
        window.location.reload()
      })
    }
    // getUser () {
    //   const user = this.$store.state.auth.user
    //   window.addEventListener('scroll', this.updateScroll)
    //   kycService.getUser(user.id).then(res => {
    //     this.user = res
    //     if (res.isDebitActive === true) this.isUserDebit = res.isDebitActive
    //     console.log('user', res)
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // },
    // checkUserDebit () {
    //   this.$store.dispatch('getUserDebit', { email: this.$store.state.auth.user.email })
    // }
  },
  mounted () {
    this.token = this.$store.state.auth.user
    // this.user = this.$store.state.kyc.detailUser
    console.log(this.user)
    window.addEventListener('scroll', this.updateScroll)
    // if (this.token) {
    //   this.getUser()
    // }
    // this.checkUserDebit()
  }
}
</script>

<style scoped>
.black {
  color: black;
}
/*HEADER*/
/* header {
  background: transparent;
  box-sizing: border-box;
  position: fixed;
  border: none;
  width: 100%;
  height: 73px;
  z-index: 10;
}
header .navbar {
  padding: 0;
  display: flex;
  align-items: center;
  height: 71px;
  background: transparent !important;
/* } */
/* header .navbar .navbar-nav {
  margin: 0 auto !important;
} */
/* .btn-primary,
.btn-outline-success:hover,
.btn-outline-success:focus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  background: #00D8FF;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  color: #FFFFFF;
  border: 2px solid #00D8FF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.btn {
  font-family: 'Inter', sans-serif;
}
.btn-secondary {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  background: #02CB6A;
  border-radius: 5px;
  border: none;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 125%;
  color: #FFFFFF;
  border: 2px solid #02CB6A;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.btn-secondary:hover {
  background: transparent;
  border: 2px solid #02CB6A;
  color: #02CB6A
}
.btn-outline-success,
.btn-primary:hover,
.btn-primary:focus {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 25px;
  border: 1px solid #00D8FF;
  box-sizing: border-box;
  border-radius: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 125%;
  color: #04C7EB;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}
.btn-primary:hover,
.btn-primary:focus {
  background: transparent;
}
.navbar-light .navbar-nav .nav-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #222531;
  font-family: 'Inter', sans-serif;
  padding: 10px 20px;
}
.navbar-light .navbar-nav .active .nav-link {
  color: #00D8FF
}
.brand {
  opacity: 1;
} */
/* header.fixed {
  position: fixed;
  width: 100%;
  left: 0;
  border: 1px solid #E0E0E0;
  background: #FFFFFF;
  z-index: 99;
  padding-left: 0;
  padding-right: 0;
  animation: smoothScroll 1s forwards;
  -webkit-transition: top 500ms ease;
  -moz-transition: top 500ms ease;
  -o-transition: top 500ms ease;
  transition: top 500ms ease;

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
header.fixed .navbar-brand img {
  opacity: 1
}
header.fixed .navbar-brand {
  background: transparent;
}
header.fixed .navbar-light .navbar-nav .nav-link {
  color: #1D1D1D
}
@keyframes smoothScroll {
  0% {
    transform: translateY(-100px);
  }
  100% {
    transform: translateY(0px);
  }
} */
</style>
