<template>
  <div class="modal-error">
    <div class="failed-header">
      <div class="icon"><a-icon type="close-circle" /></div>
    </div>
    <div class="failed-body">
      <h5 class="modal-title mt-2 mb-3"><b>Error</b></h5>
      <p class="mb-4">
        {{ message }}
      </p>
      <button class="btn mb-3" @click="$emit('close')"><b>Coba Lagi</b></button>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Icon } from 'ant-design-vue'

Vue.use(Icon)
export default {
  name: 'ModalError',
  props: ['message']
}
</script>

<style scoped>
.modal-error {
  position: fixed;
  z-index: 1000;
  width: 100%;
  top: 0;
  left: 0;
  height: 100%;
  background-color: rgba(128, 128, 128, 0.616);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Inter", sans-serif;
  justify-content: center;
}

.failed-header {
  width: 400px;
  background-color: rgb(240, 75, 73);
  display: flex;
  justify-content: center;
  height: 80px;
  color: white;
}
.icon {
  display: flex;
  font-size: 80px;
  animation: myrotate 2s;
  font-size: 70px;
  margin: 5px;
}

.failed-body {
  width: 400px;
  background-color: white;
  text-align: center;
}

.btn {
  background-color: rgb(240, 75, 73);
  border-top-left-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-bottom-left-radius: 50px;
  color: white;
}
.btn:hover {
  color: #00d8ff;
}

@keyframes myrotate {
  100% {
    transform: rotate(180deg);
  }
}
</style>
