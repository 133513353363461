<template>
  <div class="modal modal-warning modal-alert" :id="modalId" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <div
            class="swal2-icon swal2-error swal2-animate-error-icon"
            style="display: flex"
          >
            <span class="swal2-x-mark">
              <span
                class="d-flex align-items-center justify-content-center h-100"
              >
                <img src="@/assets/img/ic-info.png" class="img-warning" />
              </span>
            </span>
          </div>
        </div>
        <div class="modal-body">
          <h5 class="modal-title mb-3" style="color: black !important;">Peringatan</h5>
          <p class="mb-4">{{ message }}</p>
          <a v-if="button" @click="$emit('cancel')" class="btn btn-secondary" :style="button ? { 'margin-right': '5px' } : {}" data-dismiss="modal">Tutup</a>
          <a class="btn btn-primary" :id="buttonId" @click="$emit('clickNext')" :style="button ? { 'margin-left': '5px' } : {}" data-dismiss="modal">{{ buttonText }}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['message', 'button', 'modalId'],
  name: 'ModalWarning',
  computed: {
    buttonText () {
      if (this.button) {
        return 'Lanjut'
      } else {
        return 'Tutup'
      }
    },
    buttonId () {
      return this.modalId + 'Button'
    }
  }
}
</script>

<style>
.modal-warning .modal-header {
  background: #ffc107;
}
.modal-warning .modal-body .btn-primary {
  background: #ffc107
}
.img-warning {
  width: 8px;
  height: auto;
}
.modal-alert .modal-body .btn-primary:hover {
  color: #FFFFFF
}

.payment-box h5 {
  font-style: none;
  font-weight: none;
  line-height: none;
  padding-bottom: none;
  color: none;
  border-bottom: none;
}
</style>
